import axios from 'axios'

const root = axios.create({
    baseURL: '/api/',
    timeout: 15000
});

function get(path, params) {
    return new Promise((resolve, reject) => {
        root.get(path, {
            params
        }).then(response => {
            if (!response.data.success) {
                reject(response.data.error || 'unknown_error');
                return;
            }

            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function post(path, data) {
    return new Promise((resolve, reject) => {
        root.post(path, data).then(response => {
            if (!response.data.success) {
                reject(response.data.error || 'unknown_error');
                return;
            }

            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export default {
    get,
    post,
    root
};
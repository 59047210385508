import BaseAPI from '../base'

function apply(data) {
    return BaseAPI.post(`join/apply`, data);
}

function check(id) {
    return BaseAPI.get(`join/check/${id}`);
}

function confirm(id, data) {
    return BaseAPI.post(`join/confirm/${id}`, data)
}

export default {
    apply,
    check,
    confirm
}
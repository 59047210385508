<template>
  <div class="policy-wrapper">
    <h2 class="policy-header">Cookies</h2>
    <div class="policy-content">
      <p>
        QRWaiter platform uses cookies only as an authorization mechanism in Admin Panel, and does not track or store user data in any way using cookies.
      </p>
      <br>
      <p>
        However, additional cookie policies may apply by third parties, specifically:
      </p>
      <ul>
        <li>
            <a href="https://www.intercom.com/legal/cookie-policy">
                Intercom, which is used for providing customer support
            </a>
        </li>
        <li>
            <a href="https://policies.google.com/technologies/cookies?hl=en-US">
                Google Analytics, which is used on main website and can be used by customers in their digital menus
            </a>
        </li>
        <li>
            <a href="https://www.facebook.com/privacy/policies/cookies">
                Facebook, which can be used as a social third-party
            </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.policy-wrapper {
    width: 90%;

    margin-left: 5%;
    margin-top: 5vh;
}

.policy-content {
    color: #ededed;
    font-size: 2vh;
    margin-bottom: 5vh;
}

.policy-header {
    color: #464646;
    font-size: 6vh;
}

.policy-content ul li {
    margin-left: 3vh;
}

.policy-content kbd {
    margin-top: 5vh;
}

.policy-content h3 {
   margin-top: 2.5vh;
}
</style>

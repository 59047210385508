<template>
  <div class="terms-wrapper">
    <h2 class="terms-header">{{$t('terms.title')}}</h2>
    <div class="terms-content">
      <div>
        <b>{{$t('terms.warn')}}</b>
        <br />
        <h4>{{$t('terms.confirm')}}</h4>
        <p>
          {{$t('terms.pt1')}}
        </p>
        <p>
          {{$t('terms.pt2')}}
        </p>
        <h4>{{$t('terms.rights')}}</h4>
        <p>
          {{$t('terms.pt3')}}
        </p>
        <h4>{{$t('terms.pt4')}}</h4>
        <ul>
          <li>{{$t('terms.pt5')}}</li>
          <li>
            {{$t('terms.pt6')}}
          </li>
          <li>
            {{$t('terms.pt7')}}
          </li>
          <li>
            {{$t('terms.pt8')}}
          </li>
          <li>
            {{$t('terms.pt9')}}
          </li>
        </ul>
        <h4>{{$t('terms.pt10')}}</h4>
        <p>
          {{$t('terms.pt11')}}
        <h4>{{$t('terms.rules')}}</h4>
        <p>
          {{$t('terms.pt12')}}
        </p>
        <ul>
          <li>
            {{$t('terms.pt13')}}
          </li>
          <li>
            {{$t('terms.pt14')}}
          </li>
          <li>
            {{$t('terms.pt15')}}
          </li>
          <li>{{$t('terms.pt16')}}</li>
          <li>{{$t('terms.pt17')}}</li>
          <li>{{$t('terms.pt18')}}</li>
          <li>
            {{$t('terms.pt19')}}
          </li>
        </ul>
        <b>
          {{$t('terms.pt20')}} <a href="mailto:team@qrwaiter.com.ua">
              team@qrwaiter.com.ua</a> {{$t('terms.tg')}}
        </b>
        <br />
        <kbd>
          {{$t('terms.end')}}
        </kbd>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.terms-wrapper {
  width: 90%;

  margin-left: 5%;
  margin-top: 5vh;
}

.terms-content {
  color: #ededed;
  font-size: 2vh;
  margin-bottom: 5vh;
}

.terms-header {
  color: #464646;
  font-size: 6vh;
}

.terms-content ul li {
  margin-left: 3vh;
}

.terms-content kbd {
  margin-top: 5vh;
}

.terms-content h4 {
  margin-top: 2.5vh;
}
</style>
import BaseAPI from '../base'

function getReviews() {
    return BaseAPI.get(`landing/reviews`);
}

function callMe(name, phone) {
    return BaseAPI.post(`landing/call_me`, {
        name,
        phone
    });
}

function getPricing() {
    return BaseAPI.get(`landing/pricing`);
}

function getContacts() {
    return BaseAPI.get(`landing/contacts`);
}

export default {
    getReviews,
    callMe,
    getPricing,
    getContacts
}
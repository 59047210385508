<template>
  <div class="wrapper">
    <a href="https://qrwaiter.com.ua">
      <img src="/icons/logo-cut.png" class="logo" />
    </a>
    <h2>{{ $t("contact_title") }}</h2>
    <div class="contact-form">
      <p v-if="!call_sent">{{ $t("register.contact_info") }}</p>
      <form @submit.prevent="onSubmit" v-if="!call_sent">
        <input type="text" :placeholder="$t('register.username')" v-model="register_form.name" required />
        <div class="phone-widget">
          <input type="text" id="phone_code_input" pattern="[0-9\-]*" autocomplete="tel" :class="{
      'invalid-input': !validation.phone,
    }" required @blur="onPhoneEntered" />
        </div>
        <button :disabled="busy" class="btn btn-yellow" type="submit">
          {{ $t("register.contact_btn") }}
        </button>
      </form>

      <div class="request-sent" v-if="call_sent">
        <p>{{ $t("register.contact_sent") }}</p>
        <router-link to="/" tag="button" class="btn btn-transp">
          {{ $t("register.goback") }}
        </router-link>
      </div>
    </div>

    <div class="phones">
      <div class="item">
        <!-- <img src="/providers/kievstar.png" alt="kievstar" class="icon" /> -->
        <a :href="`tel:${contact_phone}`">
          {{ contact_phone }}
        </a>
      </div>
      <!-- <div class="item">
        <img src="/providers/vodafone.png" alt="vodafone" class="icon" />
        <a href="tel:+380951104394">+38-(095)-110-43-94</a>
      </div>
      <div class="item">
        <img src="/providers/lifecell.png" alt="lifecell" class="icon" />
        <a href="tel:+380632266170">+38-(063)-226-61-70</a>
      </div> -->
    </div>
    <a href="https://t.me/QRWaiterSupportBot" class="support">Support</a>
  </div>
</template>

<script>
import LandingAPI from '@/api/landing'

let itiInstance = null;

export default {
  data () {
    return {
      register_form: {
        name: null,
        phone: null
      },
      call_sent: false,
      validation: {
        phone: true,
      },
      busy: false,

      contact_phone: '+38-(091)-481-03-52'
    };
  },
  methods: {
    initPhoneCodeInput () {
      const el = document.getElementById("phone_code_input");

      itiInstance = window.intlTelInput(el, {
        utilsScript: "/intl-tel-input/js/utils.js",
        initialCountry: "ua",
        separateDialCode: true,
      });
    },
    fetchContactPhone () {
      LandingAPI.getContacts().then(data => {
        this.contact_phone = data.contact_phone;
      });
    },
    onPhoneEntered () {
      if (!itiInstance.isValidNumber()) {
        this.validation.phone = false;
        return;
      }

      this.register_form.phone = itiInstance.getNumber();
      this.validation.phone = true;
    },
    onSubmit () {
      this.busy = true;

      if (!this.register_form.phone) {
        this.validation.phone = false;
        this.busy = false;
        return;
      }

      LandingAPI.callMe(this.register_form.name, this.register_form.phone).then(() => {
        this.call_sent = true;
        this.busy = false;

        window.fbq("track", "Contact");
      }).catch(error => {
        alert(`Error :( (${error})`);
        this.busy = false;
      });
    }
  },
  mounted () {
    // this.initPhoneCodeInput();
    // this.fetchContactPhone();

    this.$router.push('/');

    setTimeout(() => {
      // window.$chatwoot.toggle("open");
      window.Intercom('show');
    }, 300);
  }
};
</script>


<style scoped>
.wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 5;
  top: 0;
  background: #1d1d1b;
}

h2 {
  font-weight: 100;
  color: #464646;
  padding-top: 22vh;
  text-align: right;
  padding-right: 18vh;
  font-size: 7vh;
}

.contact-form {
  position: absolute;
  top: 40vh;
  left: 15vh;
}

.contact-form p {
  color: #464646;
  font-size: 2.5vh;
  width: 40vh;
  margin-bottom: 3vh;
}

form {
  width: 50vh;
}

form input {
  display: block;
  font-size: 3vh;
  padding: 1vh;
  width: 100%;
  border: none;
  outline: none;
  background: #000000;
  margin-bottom: 2.2vh;
  color: #ededed;
  box-sizing: border-box;
}

form .btn,
.btn {
  width: 40vh;
  display: block;
  margin-top: 9vh;
  cursor: pointer;
  background: transparent;
  outline: none;
  transition: 0.3s;
  padding: 1.3vh 0;
  border: none;
  font-size: 2.3vh;
}

.btn-yellow {
  margin-top: 5.6vh !important;
  background: #ffd2a8 !important;
}

.btn-transp {
  margin-top: 3vh !important;
  color: #908f8d;
  border: 1px solid #908f8d;
  background: transparent !important;
}

.phones {
  position: absolute;
  top: 38vh;
  right: 15vh;
}

.phones .item {
  display: flex;
  flex-flow: row wrap;
  width: 55vh;
  align-items: center;
  margin-bottom: 5vh;
  font-size: 3.8vh;
  color: #ededed;
}

a {
  text-decoration: none;
  color: #ededed;
}

img.logo {
  height: 4.5vh;
  width: auto;
  position: absolute;
  left: 2vh;
  top: 2vh;
}

img.icon {
  margin-right: 5vh;
  height: 7.8vh;
  width: auto;
}

a.support {
  position: absolute;
  left: 7vh;
  bottom: 5vh;
  font-size: 3vh;
  color: #ededed;
  text-decoration: none;
}

.invalid-input {
  border: 1px solid #e2223b;
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 10vw;
    padding-right: 5vw;
    padding-top: 22vw;
    width: 70%;
    margin-left: auto;
    text-transform: uppercase;
  }

  .wrapper {
    overflow-y: auto !important;
    /* height: auto; */
    padding-bottom: 30vh;
  }

  .contact-form {
    position: relative;
    top: 10vw;
    left: 5vw;
  }

  .contact-form p {
    width: 90%;
  }

  form .btn {
    width: 100%;
  }

  form {
    width: 90%;
  }

  .phones {
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90%;
    top: 20vw;
  }

  .phones .item {
    font-size: 6.5vw;
    width: 100%;
  }

  img.icon {
    margin-right: 6vw;
    height: 13.3vw;
  }

  a.support {
    display: none;
    left: 9vw;
    bottom: 8vw;
  }
}
</style>
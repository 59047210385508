import Vue from 'vue'
import Router from 'vue-router'

import Landing from '@/views/Landing'
import Login from '@/views/Login'

// import VisitorsPage from '@/views/VisitorsPage'
// import MarketplacePage from '@/views/MarketplacePage'
import Demo from '@/views/Demo.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import TermsOfService from '@/views/TermsOfService.vue'
import PasswordRecovery from '@/views/PasswordRecovery.vue'
import ContactModal from "@/components/ContactModal.vue"
import PublicOffert from "@/views/PublicOffert.vue"
import Cookies from "@/views/Cookies.vue"

Vue.use(Router)

export default new Router({
  mode: process.env.VUE_APP_TEST_BUILD ? "hash" : "history",
  routes: [
    {
      path: "/",
      component: Landing,
    },
    {
      path: "/confirm_registration/:key",
      component: Landing,
    },
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/register",
      name: "register",
      // component: Landing
      component: {
        mounted() {
          window.location.href = "https://dashboard.qrwaiter.com.ua/register";
        },
      },
    },
    {
      path: "/visitor",
      component: Landing,
    },
    {
      path: "/marketplace",
      component: Landing,
    },
    {
      path: "/demo/:type",
      component: Demo,
    },
    {
      path: "/password_recovery/:token",
      component: PasswordRecovery,
    },
    {
      path: "/privacy_policy",
      component: PrivacyPolicy,
    },
    {
      path: "/terms",
      component: TermsOfService,
    },
    {
      path: "/contact_phones",
      component: ContactModal,
    },
    {
      path: "/public_offert",
      component: PublicOffert,
    },
    {
      path: "/cookies",
      component: Cookies,
    },
    {
      path: "/viber",
      component: {
        mounted() {
          window.location.href = "viber://pa?chatURI=qrwaiter";

          this.$router.push("/");
        },
      },
    },
  ],
});

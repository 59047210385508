<template>
  <div class="wrapper">
    <Navbar />

    <section class="intro">
      <h1 v-html="$t('landing.intro.header')"></h1>
      <Button variant="primary" class="intro-btn green" @click="goToRegister">{{
        $t("landing.intro.action")
      }}</Button>
      <Button variant="secondary" class="intro-btn green" @click="openContacts()">{{ $t("landing.intro.contact")
        }}</Button>

      <div class="green-rect">
        <div class="pics-wrapper">
          <img src="/pics/intro-screen1.png" class="pic1" alt="QRWaiter - Приклад онлайн меню" />
          <img src="/pics/intro-screen2.png" class="pic2" alt="QRWaiter - Приклад онлайн меню" />
        </div>
      </div>
      <div class="view-as">
        <!-- <TabSwitcher
          theme="dark"
          class="w-36"
          v-model="mode"
          :tabs="[
            {
              text: $t('page_switcher.for_cafes'),
              value: 'cafes',
            },
            {
              text: $t('page_switcher.for_users'),
              value: 'visitors',
            },
          ]"
        /> -->
        <!-- <ul>
          <li class="active">{{ $t('page_switcher.for_cafes') }}</li>
          <router-link to="/visitor">
            <li>{{ $t('page_switcher.for_users') }}</li>
          </router-link>
        </ul> -->
      </div>
    </section>

    <section class="video-demo">
      <div class="video-box">
        <img src="/pics/play-btn.svg" v-if="!showVideo" @click="showVideo = true" class="play" />
        <iframe v-if="showVideo" :src="youtubeLink" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <h2>{{ $t("landing.see") }}</h2>
      </div>
    </section>

    <section class="system-demo">
      <div class="demo-wrapper">
        <div class="info">
          <h2>{{ $t("landing.how") }}</h2>
          <p>{{ $t("landing.guests") }}</p>
          <div class="social-media">
            <img src="/icons/fb.png" alt="QRWaiter - Facebook Icon" />
            <img src="/icons/inst.png" alt="QRWaiter - Instagram Icon" />
          </div>
          <!-- <div class="via-qr">
            <p>{{ $t("landing.app") }}</p>
            <a
              href="https://apps.apple.com/ua/app/qrdelivery/id1507792224"
              target="_blank"
              ><img src="/icons/app-store.svg" alt="QRWaiter - App Store Icon"
            /></a>
            <a
              href="https://play.google.com/store/apps/details?id=com.webqrdelivery.qrdelivery"
              target="_blank"
              ><img src="/icons/g-store.png" class="g-play"
            /></a>
          </div> -->
          <!-- <a class="demo-link" href="https://cafe.qrwaiter.com.ua/demo_cafe" target="_blank">
            <button class="btn btn-green">Demo</button>
          </a> -->
        </div>

        <div class="demo-pics-box">
          <!-- <div v-for="i in [picDemos]" :key="i.length">
          <img :src="currentImg" />
          </div>-->
          <img :src="currentSystemDemoImage" />
          <div class="switcher-box">
            <!-- <div class="pic-switcher" v-for="i in 10" :key="i"></div> -->
            <ImagesSwitcher :images="picDemos" @update="(v) => (currentSystemDemoImage = v)" />
          </div>
        </div>
      </div>

      <demo-popup @close="closePopup" v-if="showClientDemo" class="slot-box">
        <h2 slot="header">C-DEMO</h2>
        <p slot="info">{{ $t("demo.mobile") }}</p>
        <img slot="hero" src="/icons/hero/sad.svg" class="hero" />
        <img slot="qr-pic" class="qr-pic" src="/pics/app-demo-qrcode.png" />
      </demo-popup>
    </section>

    <section class="features">
      <div class="feature-box">
        <img :src="currentFeatureImage" class="preview" alt="QRWaiter - Переваги" />
        <div class="info">
          <div v-if="featureIndex == 0">
            <h2>{{ $t("landing.stickers") }}</h2>
            <p>{{ $t("landing.design") }}</p>

            <p>{{ $t("landing.waterproof") }}</p>

            <p class="grey">{{ $t("landing.admin") }}</p>
          </div>

          <div v-if="featureIndex == 1">
            <h2>QR-WAITER AI</h2>
            <p>{{ $t("landing.algorythm") }}</p>
          </div>

          <div v-if="featureIndex == 2">
            <h2>{{ $t("landing.pay3") }}</h2>
            <p>{{ $t("landing.visitorspay") }}</p>

            <p>{{ $t("landing.pay") }}</p>

            <p class="grey">{{ $t("landing.liqpay") }}</p>
          </div>

          <div v-if="featureIndex == 3">
            <h2>{{ $t("landing.multilangtitle") }}</h2>
            <p>{{ $t("landing.multilang") }}</p>

            <p class="grey">{{ $t("landing.autotranslate") }}</p>
          </div>

          <div v-if="featureIndex == 4">
            <h2>{{ $t("landing.quicktitle") }}</h2>
            <p>{{ $t("landing.quick1") }}</p>

            <p>{{ $t("landing.quick2") }}</p>
          </div>

          <div v-if="featureIndex == 5">
            <h2>{{ $t("landing.comfortabletitle") }}</h2>
            <p>{{ $t("landing.comfortable1") }}</p>

            <p>{{ $t("landing.comfortable2") }}</p>

            <p class="grey">{{ $t("landing.comfortable3") }}</p>
          </div>

          <div v-if="featureIndex == 6">
            <h2>{{ $t("landing.tabletitle") }}</h2>
            <p>{{ $t("landing.table1") }}</p>

            <p class="grey">{{ $t("landing.table2") }}</p>
          </div>

          <div v-if="featureIndex == 7">
            <h2>{{ $t("landing.servicestitle") }}</h2>
            <p>{{ $t("landing.services1") }}</p>

            <p>{{ $t("landing.services2") }}</p>

            <p>{{ $t("landing.services3") }}</p>
          </div>

          <div class="switcher-box">
            <!-- <div 
              class="pic-switcher" 
              v-for="item in 8" 
            :key="item"></div>-->
            <ImagesSwitcher :images="featurePics" horizontal @update="(v) => (currentFeatureImage = v)" />
          </div>
        </div>
      </div>
    </section>

    <section class="waiter-queue">
      <div class="wq-box">
        <h2>{{ $t("landing.ez1") }}</h2>
        <h5>{{ $t("landing.ez2") }}</h5>
        <h2>{{ $t("landing.ez3") }}</h2>
        <div class="info">
          <p>{{ $t("landing.ez4") }}</p>
          <p>{{ $t("landing.ez5") }}</p>
          <div class="waiter-app">
            <img src="/icons/telega.svg" />
            <img src="/icons/app-store.svg" />
            <img src="/icons/g-store.png" class="g-play" />
          </div>

          <!-- <a class="demo-link" href="https://queue.qrwaiter.com.ua/login/1C1A-B3C4-14E0?pin=MDAwMA==" target="_blank">
            <button class="btn btn-dark">Demo</button>
          </a> -->

          <demo-popup @close="closePopup" v-if="showQueueDemo" class="slot-box">
            <h2 slot="header">W-DEMO</h2>
            <p slot="info">{{ $t("demo.mobile") }}</p>
            <img slot="hero" src="/icons/hero/hello.svg" class="hero" />
            <img slot="qr-pic" class="qr-pic" src="/pics/queue-demo-qrcode.png" />
          </demo-popup>

          <img src="/pics/features/Flying-Iphones.png" class="queue-panel" alt="QRWaiter - Додаток для офіціанта" />
        </div>
      </div>
    </section>

    <section class="integrations">
      <h2>{{ $t("landing.postitle") }}</h2>
      <div class="pos-box">
        <img src="/pics/features/poster.png" alt="QRWaiter - Poster інтеграція" />
        <div class="info">
          <h4>{{ $t("landing.pos1") }}</h4>
          <p>{{ $t("landing.pos2") }}</p>
          <p>{{ $t("landing.pos3") }}</p>
        </div>
      </div>
    </section>

    <section class="pay-plans">
      <Plans />
    </section>

    <!-- OUTDATED -->
    <section class="marketplace" v-if="false">
      <div class="wrapper">
        <img src="/pics/marketplace-text.svg" class="marketplace-text" />
        <div class="info">
          <h4 v-html="$t('landing.marketplace')"></h4>
          <p>{{ $t("landing.marketplace1") }}</p>
          <!-- <a href="/marketplace" class="demo-link" target="_blank">
            <button class="btn mt-30 btn-grey">{{$t('landing.more')}}</button>
          </a>-->

          <a href="/demo/marketplace" class="demo-link" target="_blank">
            <button class="btn mt-30 btn-red">{{ $t("landing.watch") }}</button>
          </a>
        </div>

        <img src="/pics/marketplace.png" class="mobile" />
      </div>
    </section>
    <demo-popup @close="closePopup" v-if="showMarketplaceDemo" class="slot-box">
      <h2 slot="header">MARKETPLACE</h2>
      <p slot="info">{{ $t("demo.mobile") }}</p>
      <img slot="hero" src="/icons/hero/wine.svg" class="hero wine" />
      <img slot="qr-pic" class="qr-pic" src="/pics/client-app-qr.png" />
    </demo-popup>

    <!-- <section class="our-clients" v-if="cafeReviews && cafeReviews.length">
      <h3 class="title">{{$t('landing.partners')}}</h3>
      <p class="info">{{$t('landing.partners1')}}</p>
      <div class="cafes-list">
        <img
          v-for="(review,index) in cafeReviews"
          :src="review.image"
          :key="index"
          :class="selectedCafeReview == index ? 'active':''"
          @click="selectedCafeReview = index"
        />
      </div>

      <div class="reviews-box">
        <h3>{{$t('landing.review')}} {{ currentCafeReview.name }}</h3>
        <p>{{ currentCafeReview.text }}</p>
        <a :href="currentCafeReview.facebook" v-if="currentCafeReview.facebook">
          <img src="/icons/fb-grey.svg" alt />
        </a>
        <a :href="currentCafeReview.instagram" v-if="currentCafeReview.instagram">
          <img src="/icons/inst-grey.svg" alt />
        </a>
      </div>
    </section>-->

    <!-- <section class="our-clients">
      <h3 class="title">{{ $t("landing.partners") }}</h3>
      <p class="info">{{ $t("landing.partners1") }}</p>
      <div class="cafes-list">
        <img
          alt="Antonio Family Cafe"
          src="https://cdn.qrwaiter.com.ua/legacy/cafelogo_46e37d475c3ae9e9.jpg"
        />

        <img
          alt="Chia Bowl"
          src="https://cdn.qrwaiter.com.ua/cafe_5eff530225545558d5206fba_jLEfYxgQycuf_md.jpg"
        />

        <img
          alt="Bagel Lounge"
          src="https://cdn.qrwaiter.com.ua/cafe_5f3f09488c9832645789159b_QmLHChKw8Rbt_md.png"
        />

        <img
          alt="Uzh Fruit"
          src="https://cdn.qrwaiter.com.ua/cafe_5fabfe3488898627acb602f3_Wfc2pPBas6T9_md.jpg"
        />

        <img
          alt="Smagolino"
          src="https://cdn.qrwaiter.com.ua/legacy/cafelogo_a87edcb265eb87c3920e.png"
        />

        <img
          alt="Uno Sushi"
          src="https://cdn.qrwaiter.com.ua/cafe_6308c90aa8f7a72a31f78ffc_csNCXJNKLoQb_md.png"
        />

        <img
          alt="Magic Bowls"
          src="https://cdn.qrwaiter.com.ua/legacy/cafelogo_cc198852c690de4313f0.jpg"
        />
      </div>
    </section> -->

    <Register id="register" :register_key="$route.params.key || null" />

    <Footer />
  </div>
</template>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Chadwick', 'ChadwickBold', Arial, Helvetica, sans-serif;
}

.intro {
  padding: 28vh 0 0 11vh;
}

.intro h1 {
  color: #ededed;
  font-size: 5.5vh;
  line-height: 0.9;
  padding-bottom: 5.6vh;
  font-family: 'ChadwickBold';
  text-transform: uppercase;
  width: 54vh;
  font-weight: 500;
}

h1::v-deep span {
  font-size: 3.2vh;
  display: block;
  line-height: normal;
}

.mode-switcher {
  width: 35vh;
  background: #2e2e2e;
  color: #908f8d;
}

.intro-btn {
  padding: 1.8vh 2.6vh;
  margin-bottom: 2.3vh;
  min-width: 36vh;
  border-radius: 8px;
  justify-content: center;
}

.green-rect {
  background: #129b81;
  border-radius: 16px 0 0 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 86vh;
  height: 37vh;
}

.green-rect img {
  height: 65vh;
  width: auto;
  position: relative;
}

.pics-wrapper {
  position: relative;
  float: right;
  width: 75vh;
}

.green-rect img.pic1 {
  top: -18vh;
}

.green-rect img.pic2 {
  top: -7vh;
}

.view-as {
  position: absolute;
  bottom: 9vh;
  left: 11vh;
}

.w-36 {
  width: 36vh;
}

.view-as ul {
  list-style: none;
}

.view-as li.active {
  background: #129b81;
  color: #ededed;
}

ul a {
  text-decoration: none;
}

.view-as li {
  color: #908f8d;
  text-align: center;

  font-size: 2.2vh;
  cursor: pointer;
  padding: 0.5vh 2vh;
  margin-bottom: 3vh;
}

.view-as li:hover {
  color: #ededed;
}

.view-as li:last-child {
  margin-bottom: 0;
}

/* section video-demo */

.video-box {
  height: 80vh;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 210, 168, 0.65), rgba(255, 210, 168, 0.65)),
    url('/pics/video-intro.png');
  position: relative;
  background-size: cover;
}

iframe {
  width: 100%;
  position: inherit;
  z-index: 3;
  height: 80vh;
}

img.play {
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
  height: 10vh;
}

img.play:hover {
  height: 12vh;
  width: auto;
}

.video-box h2 {
  font-size: 5vh;
  text-transform: uppercase;
  font-weight: 500;
  right: 6vh;
  bottom: 4vh;
  position: absolute;
}

/* system demo */
.system-demo {
  padding: 10vh 18vh;
}

.demo-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.system-demo h2 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 7vh;
  padding-bottom: 7vh;
  color: #464646;
}

.system-demo p {
  color: #ededed;
  font-size: 2.5vh;
  width: 56vh;

  line-height: normal;
}

.social-media {
  padding-top: 3vh;
}

.social-media img {
  height: 5.5vh;
  width: auto;
  margin-right: 2vh;
}

.via-qr {
  padding-top: 9.5vh;
}

.via-qr img,
.waiter-app img {
  height: 5.5vh;
  width: auto;
  margin: 3vh 2vh 0 0;
}

.via-qr img.g-play,
.waiter-app img.g-play {
  height: 8vh;
  top: 1.2vh;
  position: relative;
}

.system-demo .btn-green {
  border: 1px solid #129b81;
  color: #129b81;
}

.btn-yellow {
  margin-top: 6.6vh !important;
  background: #ffd2a8 !important;
  border: none !important;
}

.btn-dark {
  color: #1d1d1b;
  position: absolute;
  bottom: 6.6vh;
  border: 1px solid #1d1d1b;
}

.btn-dark:hover {
  background: #1d1d1b;
  color: #ededed;
}

.btn-grey {
  color: #464646;
  border: 1px solid #464646;
}

.btn-grey:hover {
  background: #464646;
  color: #ededed;
}

.btn-red {
  color: #e2223b;
  border: 1px solid #e2223b;
}

.btn-red:hover {
  background: #e2223b;
  color: #ededed;
}

.btn {
  width: 50vh;
  display: block;
  margin-top: 9vh;
  cursor: pointer;
  background: transparent;
  outline: none;
  transition: 0.3s;
  padding: 1.3vh 0;
  font-size: 2.3vh;
}

.btn-contact {
  width: 30vh;
  margin-left: 11vh;
  background: transparent;
  color: #ffd2a8;
  border: 1px solid #ffd2a8;
}

.btn-contact:hover {
  background-color: #ffd2a8;
  color: #1d1d1b;
}

.system-demo .btn:hover {
  background: #129b81;
  color: #ededed;
}

.demo-pics-box {
  position: relative;
  right: 0;
  top: 0;
}

.demo-pics-box img {
  height: 65.5vh;
  width: auto;
}

.switcher-box {
  position: absolute;
  right: -8vh;
  top: 13vh;
}

.pic-switcher {
  background: #464646;
  height: 2vh;
  width: 2vh;
  margin-bottom: 4vh;
}

.pic-switcher.active {
  background: #ededed;
}

/* features */
.feature-box {
  padding: 10vh 18vh;
  display: flex;
  width: 100%;
  justify-content: center;
}

.feature-box .info {
  padding: 16vh 0 0 10vh;
}

.info .switcher-box {
  position: absolute;
  right: auto;
  bottom: 25vh;
  /* transform: rotate(90deg); */
}

.feature-box h2 {
  color: #908f8d;
  font-size: 4.5vh;
  width: 33vh;
  font-weight: 100;
}

.feature-box p {
  font-size: 2.7vh;
  padding-top: 2.2vh;
  width: 42vh;
  line-height: normal;
  color: #ededed;
}

p.grey {
  color: #909090;
}

.feature-box img.preview {
  animation: fade 1s;
  height: 72vh;
  transition: 0.5s;
  width: auto;
}

@keyframes fade {
  0% {
    opacity: 0.1;
  }

  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/* waiter panel */
.wq-box {
  padding: 5vh 8vh;
  position: relative;
  height: 100%;
}

.wq-box h2 {
  font-size: 4.2vh;
  color: #ededed;
  font-weight: 500;
  padding-left: 1vh;
  line-height: normal;
}

.wq-box h5 {
  font-size: 2vh;
  padding-left: 1vh;
  color: #908f8d;
}

.wq-box .info p {
  font-size: 2.2vh;
  width: 47vh;
}

.wq-box .info p:last-of-type {
  font-size: 2.2vh;
  padding-top: 2vh;
  width: 100%;
}

.wq-box h2:last-of-type {
  padding-bottom: 5vh;
}

.wq-box .info {
  color: #464646;
  background: #cbd9e6;
  width: 100%;
  padding: 6.6vh;
  position: relative;
  bottom: 0;
  height: 58vh;
}

.waiter-app {
  padding-top: 4.4vh;
}

.waiter-app img {
  margin-top: 0;
}

img.queue-panel {
  height: 66vh;
  top: -13vh;
  right: 0;
  display: block;
  position: absolute;
  width: auto;
}

/* integrations */
.integrations {
  background: black;
  width: 100%;
  height: 63vh;
  padding: 10vh 12vh 10vh 20vh;
}

.integrations h2 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 7vh;
  text-align: right;
  line-height: 1;
  color: #464646;
}

.pos-box {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}

.pos-box img {
  height: 33vh;
  width: auto;
}

.pos-box .info {
  color: #f8f8f8;
  width: 75vh;
}

.pos-box .info h4 {
  padding-top: 7.2vh;
  font-weight: 500;
  font-size: 4.4vh;
}

.pos-box .info p {
  font-size: 2.2vh;
  padding-top: 2vh;
}

/* pay-plans */
.pay-plans {
  background: #fcfcfc;
}

.plans-wrapper {
  display: block;
  width: 138vh;
  margin: 0 auto;
  position: relative;
  padding: inherit;
  padding-left: 0;
}

.plans-wrapper img {
  position: absolute;
  right: 0;
  bottom: -8vh;
  height: 41.5vh;
}

.pay-plans h2 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 7vh;
  line-height: 1;
  padding-right: 22vh;
  text-align: right;
  color: #464646;
}

.modes {
  padding-top: 5vh;
}

.modes .item {
  font-size: 2.8vh;
  text-align: center;
  margin-bottom: 2.2vh;
  z-index: 2;
  position: relative;
  cursor: pointer;
  padding: 2vh 0;
  width: 19vh;
  transition: 0.3s;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.modes .item.active {
  width: 41vh;
  padding: 4vh 0;
  font-size: 4.7vh;
}

.item span {
  color: #aeccc6;
}

.item.lite,
.system-info.lite {
  background: #ededed;
}

.item.inside,
.system-info.inside {
  color: #ededed;
  background: #1d1d1b;
}

.item.preorder,
.system-info.preorder {
  background: #ffd2a8;
}

.item.delivery,
.system-info.delivery {
  background: #cbd9e6;
}

.item.reserve,
.system-info.reserve {
  background: #aeccc6;
}

.system-info {
  height: 70vh;
  padding: 7vh 8vh 0 20vh;
  position: absolute;
  top: 5vh;
  right: 0;
  z-index: 0;
  width: 110vh;
}

.system-info.inside {
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);
}

h3.mode-price {
  font-size: 10vh;
  line-height: 1;
  position: absolute;
  bottom: 5vh;
}

.mode-price span {
  font-size: 2vh;
  margin-left: -2.5vh;
}

.system-info p {
  padding-top: 2.2vh;
  font-size: 2.7vh;
}

/* marketplace */
.marketplace {
  padding: 10vh 0;
}

.marketplace .wrapper {
  display: flex;
  width: 100%;
  padding-top: 5vh;
  justify-content: center;
}

img.marketplace-text {
  width: 68%;
  position: absolute;
  height: 16vh;
  margin: 0 auto;
  display: block;
}

.marketplace .wrapper .info {
  width: 45vh;
  position: relative;
  top: 16vh;
}

.marketplace h2 {
  font-size: 13.5vh;
  letter-spacing: 5px;
  line-height: 0.8;
  color: #464646;
}

.marketplace h5 {
  color: #464646;
  font-size: 3.7vh;
  padding-left: 1vh;
}

.marketplace h4 {
  color: #ededed;
  font-size: 4.4vh;
  font-weight: 500;
  width: 63vh;
  text-transform: uppercase;
  padding-top: 4.4vh;
}

.marketplace h4::v-deep span {
  display: block;
  font-size: 2.7vh;
}

.marketplace p {
  color: #908f8d;
  padding-top: 4.4vh;
  font-size: 2.7vh;
  width: 45vh;
}

.marketplace img.mobile {
  height: 89vh;
  position: relative;
  top: 3vh;
  right: -15vh;
  width: auto;
}

/* clients */
.our-clients {
  height: auto !important;
}

.our-clients h3 {
  font-size: 4.4vh;
  padding: 10vh 19vh 0;
  font-weight: 500;
  color: #908f8d;
}

.our-clients p {
  font-size: 2.3vh;
  padding: 1vh 19vh 0;
  color: #ffffff;
}

.cafes-list {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10vh 10vh 0;
  text-align: center;
}

.cafes-list img {
  height: 8.5vh;
  width: 16vh;
  object-fit: cover;
  transition: 0.5s;
  margin-right: 2vh;

  cursor: pointer;
}

.cafes-list img:hover,
.cafes-list img.active {
  /* transform: translateY(-25%); */
}

.reviews-box {
  padding: 10vh 19vh;
}

.reviews-box h3 {
  padding: 0;
  font-weight: 500;
}

.reviews-box p {
  padding: 2vh 0 0 0;
  width: 50%;
  font-size: 2.2vh;
}

.reviews-box img {
  margin-top: 5vh;
  height: 5.5vh;
  margin-right: 2vh;
}

/* general  */
.intro,
.video-demo,
.system-demo,
.features,
.waiter-queue,
.marketplace,
.our-clients,
.registration {
  position: relative;
  height: 100vh;
  background: #1d1d1b;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-30 {
  margin-top: 3.3vh;
}

/* slots */

.slot-box {
  padding: 2vh 5vh;
}

.slot-box h2 {
  font-size: 8vh;
  position: relative;
  right: 11.3vh;
  text-align: right;
  color: #464646;
  top: 10vh;
  padding-bottom: 0;
}

.slot-box img.hero {
  height: 20vh;
  width: auto;
  position: absolute;
  left: 20.5vh;
  bottom: 19vh;
}

img.qr-pic {
  height: 40vh;
  width: auto;
  position: absolute;
  right: 30vh;
  bottom: 19vh;
}

.hero.wine {
  height: 30vh !important;
  left: 14vh !important;
}

.w-100 {
  width: 100% !important;
}

.demo-link {
  text-decoration: none;
}

.partner-cafe-logo {
  object-fit: cover;
  width: 16.8vh;
  height: 8.5vh;
}

/* mobile\tablet */

@media screen and (max-width: 768px) {
  .intro {
    padding: 10vh 3.4vh;
  }

  .wrapper {
    overflow-x: hidden;
    width: 100%;
  }

  .intro {
    height: 100vh;
  }

  .cafes-list {
    justify-content: initial;
  }

  .btn {
    width: 91%;
  }

  .intro-btn {
    display: none;
  }

  .btn-contact {
    position: absolute;
    bottom: 8vw;
    width: 90%;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .via-qr img.g-play,
  .waiter-app img.g-play {
    height: auto;
    width: 55%;
    top: 0;
    margin: 0 0 0 -2.7vw;
    position: relative;
  }

  .plans-wrapper {
    display: flex;
    width: auto;
  }

  .intro h1 {
    text-align: center;
    font-size: 4.4vh;
    padding: 5vh 0;
    width: 100%;
  }

  .intro h1::v-deep span {
    display: block;
    font-size: 2.5vh;
    padding-top: 1.2vh;
    text-align: center;
  }

  .green-rect {
    top: 48%;
    height: 25vh;
    transform: initial;
  }

  .green-rect img {
    height: 45vh;
  }

  .pics-wrapper {
    display: flex;
    top: 3.3vh;
    width: 40.5vh;
  }

  .green-rect img.pic1 {
    order: 2;
    top: -12vh;
  }

  .green-rect img.pic2 {
    order: 1;
    top: -22vh;
  }

  .view-as {
    bottom: 4vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90%;
  }

  .tabs-switcher {
    width: 100%;
  }

  .video-box {
    height: 22.5vh;
  }

  img.play {
    height: 7vh;
  }

  .video-demo {
    height: 35vh;
  }

  .demo-pics-box img {
    height: auto;
    width: 91.5%;
    margin-left: -2vh;
  }

  .demo-wrapper {
    flex-flow: column;
  }

  .video-box h2 {
    position: relative;
    text-align: right;
    top: 23vh;
    right: 3vh;
    color: #ededed;
    font-size: 3vh;
  }

  .system-demo {
    padding: 2vh 2.5vh;
    height: auto;
    margin-bottom: -1px;
  }

  .system-demo p {
    width: 90%;
    font-size: 3vh;
  }

  .system-demo h2 {
    font-size: 4.3vh;
    padding-bottom: 3.7vh;
  }

  .system-demo .btn-green {
    bottom: -6vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    position: absolute;
  }

  .social-media {
    padding-top: 3.7vh;
  }

  .demo-pics-box {
    right: 0;
    width: 100%;
    position: relative;
  }

  .demo-pics-box .switcher-box {
    right: 0;
    top: 5vh;
  }

  .via-qr {
    padding: 7.4vh 0;
  }

  .via-qr img.g-play {
    height: 100%;
    width: 38vw;
    top: 1.3vh;
    position: relative;
  }

  .via-qr img {
    height: 5vh;
  }

  .feature-box {
    flex-flow: column wrap;
    padding: 2.5vh;
  }

  .feature-box .switcher-box {
    top: 2vh;
    left: 0;
    right: 0;
  }

  .feature-box img.preview {
    height: 100%;
    width: 40vh;
    margin: 13vh auto 5vh;
  }

  .feature-box .info {
    padding: 0;
  }

  .feature-box p {
    width: 85%;
  }

  .feature-box p.grey {
    width: 100%;
  }

  .waiter-queue {
    height: 138vh;
  }

  .wq-box {
    padding: 10vh 0 0 0;
  }

  .wq-box h2:first-child {
    padding: 5vh 2.5vh 0;
  }

  .wq-box h5 {
    padding: 1vh 2.5vh 0;
  }

  .wq-box h2:last-of-type {
    padding: 1vh 2.5vh 5vh;
  }

  .wq-box .info {
    top: 25vh;

    padding: 22vh 5vh 0 2vh;
    height: 75vh;
  }

  .wq-box .info p {
    width: initial;
  }

  img.queue-panel {
    top: -25vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 44vh;
  }

  .waiter-app {
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding-top: 2vh;
  }

  .waiter-app img {
    height: 5.5vh;
    width: 17.3vh;
    margin: 1.8vh 0 0 0;
  }

  .waiter-app img:first-child {
    margin: 0;
  }

  .integrations {
    height: auto;
    position: relative;
    top: 0;
    padding: 10vh 5.4vw 0;
  }

  .integrations h2 {
    font-size: 4.5vh;
    text-align: left;
  }

  .pos-box {
    flex-flow: column wrap;
    width: 100%;
  }

  .pos-box .info {
    width: 100%;
    padding-bottom: 5vh;
  }

  .pos-box .info h4 {
    padding-top: 0;
    width: 77%;
  }

  .pos-box .info p {
    font-size: 2.5vh;
    width: 80%;
  }

  .pos-box img {
    height: auto;
    width: 100%;
    transform: scale(1.18);
    margin: 3vh auto;
  }

  .pay-plans {
    padding: 0;
  }

  .pay-plans h2 {
    padding: 12.5vh 2.5vh 0;
    text-align: left;
    font-size: 4.4vh;
  }

  iframe {
    width: 100%;
    height: 24vh;
  }

  .system-info {
    width: 100%;
    position: absolute;
    right: 0;
    top: 10vh;
    z-index: 1;
    height: 77vh;
    padding: 2.5vh;
  }

  .plans-wrapper img {
    height: 15vh;
    bottom: -3vh;
  }

  .modes {
    display: flex;
    overflow: auto;
    padding: 5vh 0 1vh;
  }

  .modes .item.lite {
    min-width: 22vh !important;
  }

  .modes .item {
    display: block;
    height: fit-content;
    text-align: center;
    min-width: 20vh;
    padding: 2vh;
    font-size: 2.7vh;
    margin: 0 2vh 0 0;
  }

  .modes .item.active {
    font-size: 3vh;
    padding: 2vh;
    width: 22vh;
  }

  .marketplace {
    padding-top: 13vh;
  }

  .marketplace,
  .registration {
    height: auto !important;
    padding-bottom: 5vh;
    top: 3.5vh;
  }

  .features {
    height: 120vh;
  }

  .marketplace .info .btn {
    width: 100% !important;
  }

  .marketplace .wrapper {
    justify-content: initial;
    flex-flow: column wrap;
  }

  .marketplace .wrapper .info {
    width: 100%;
    padding: 2.5vh;
    top: 0;
    order: 2;
  }

  img.marketplace-text {
    height: auto;
    width: 95%;
    display: block;
    left: 0;
    right: 0;
    margin: -3vh auto 0;
  }

  .marketplace h2 {
    font-size: 5.5vh;
    letter-spacing: 0;
    font-weight: 400;
  }

  .marketplace h4,
  .marketplace p {
    width: 100%;
  }

  .marketplace h5 {
    padding-left: 0.5vh;
    font-size: 2vh;
  }

  .marketplace img.mobile {
    right: 0;
    top: 0;
    order: 1;
    height: auto;
    width: 100%;
  }

  .cafes-list {
    display: flex;
    justify-content: initial;
    overflow: auto;
    padding: 5vh 2vh 0;
  }

  .reviews-box h3 {
    font-size: 3.7vh;
  }

  .reviews-box img {
    margin-top: 3.7vh;
  }

  .cafes-list img:last-child {
    padding-right: 2vh;
  }

  .our-clients {
    padding-top: 12.5vh;
  }

  .our-clients h3.title,
  .our-clients p.info {
    padding: 0 0 0 2.5vh;
  }

  .our-clients h3,
  .our-clients p {
    padding: 0;
  }

  .reviews-box {
    padding: 2.5vh;
  }

  .reviews-box p {
    padding-top: 2vh;
    width: 80%;
  }
}
</style>


<script>
import demoPopup from '@/components/demoPopup.vue'
import ImagesSwitcher from '@/components/ImagesSwitcher.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Plans from '@/components/Plans.vue'
import Register from '@/views/Register.vue'
import Button from '@/components/Button.vue'
// import TabSwitcher from "@/components/TabSwitcher.vue";

import LandingAPI from '@/api/landing'

export default {
  data () {
    return {
      mode: 'cafes',
      picDemos: [
        '/pics/system-demo/pic1.png',
        '/pics/system-demo/pic2.png',
        '/pics/system-demo/pic3.png',
        '/pics/system-demo/pic4.png',
        '/pics/system-demo/pic5.png',
        '/pics/system-demo/pic6.png',
        '/pics/system-demo/pic7.png',
        '/pics/system-demo/pic8.png',
        '/pics/system-demo/pic9.png',
        '/pics/system-demo/pic10.png'
      ],
      featurePics: [
        '/pics/features/QR-codes.png',
        '/pics/features/AIWaiter.png',
        '/pics/features/Payments.png',
        '/pics/features/Multilingual.png',
        '/pics/features/Fast-access.png',
        '/pics/features/UI.png',
        '/pics/features/Table-recognition.png',
        '/pics/features/Options.png'
      ],
      // cafeReviews: [
      //   {
      //     name: "ЇЖ ДВІЖ",
      //     facebook: "https://facebook.com/yizh.dvizh",
      //     instagram: "https://instagram.com/yizh.dvizh",
      //     image: "/pics/cafes/yizh-dvizh.jpg",
      //     text:
      //       "Наша найновіша розробка – складний алгоритм, який аналізує поведінку всіх користувачів та їх вподобання, щоб рекомендувати найбільш підходящі для них страви. Працює індивідуально для кожного закладу."
      //   },
      //   {
      //     name: "Chicken Mood",
      //     facebook: "https://facebook.com/chicke",
      //     instagram: "https://instagram.com/yizh.dvizh",
      //     image: "/pics/cafes/chicken-mood.jpg",
      //     text:
      //       "Наша найновіша розробка – складний алгоритм, який аналізує поведінку всіх користувачів та їх вподобання, щоб рекомендувати найбільш підходящі для них страви. Працює індивідуально для кожного закладу."
      //   },
      //   {
      //     name: "DED eats",
      //     facebook: "https://facebook.com/yizh.dvizh",
      //     instagram: "https://instagram.com/yizh.dvizh",
      //     image: "/pics/cafes/ded-eats.jpg",
      //     text:
      //       "Наша найновіша розробка – складний алгоритм, який аналізує поведінку всіх користувачів та їх вподобання, щоб рекомендувати найбільш підходящі для них страви. Працює індивідуально для кожного закладу."
      //   },
      //   {
      //     name: "Basicilo",
      //     facebook: "https://facebook.com/yizh.dvizh",
      //     instagram: "https://instagram.com/yizh.dvizh",
      //     image: "/pics/cafes/basillico.jpg",
      //     text: "Базіліко відгук"
      //   },
      //   {
      //     name: "Kebabushka",
      //     facebook: "https://facebook.com/yizh.dvizh",
      //     instagram: "https://instagram.com/yizh.dvizh",
      //     image: "/pics/cafes/kebabushka.jpg",
      //     text: "У мене шаурма, а ти поїш гавна"
      //   },
      //   {
      //     name: "MAFFIN",
      //     facebook: "https://facebook.com/yizh.dvizh",
      //     instagram: "https://instagram.com/yizh.dvizh",
      //     image: "/pics/cafes/maffin.jpg",
      //     text: "Мафін, мафін, в жопу трахен"
      //   },
      //   {
      //     name: "Millenium",
      //     facebook: "https://facebook.com/yizh.dvizh",
      //     instagram: "https://instagram.com/yizh.dvizh",
      //     image: "/pics/cafes/millenium.jpg",
      //     text: "Мій заклад мілленіум"
      //   }
      // ],
      cafeReviews: [],
      selectedCafeReview: 0,
      selectedFeature: 0,

      showClientDemo: false,
      showQueueDemo: false,
      showMarketplaceDemo: false,

      showVideo: false,
      menuOpened: false,
      selectedSystem: 'inside',

      currentSystemDemoImage: null,
      currentFeatureImage: null,
      scrollPosition: null,
      showContactModal: false,

      pricing: {
        inside: 25,
        preorder: 13,
        delivery: 25,
        reserve: 7,
        inside_lite: 5,
        pro: 50
      }
    }
  },
  methods: {
    openContacts () {
      // window.$chatwoot.toggle("open");
      window.Intercom('show');
    },
    closePopup () {
      this.showClientDemo = false
      this.showQueueDemo = false
      this.showMarketplaceDemo = false
    },
    goToMarketplace () {
      this.$router.push('/marketplace')
    },
    updateScroll () {
      this.scrollPosition = window.scrollY
    },
    fetchReviews () {
      LandingAPI.getReviews()
        .then(data => {
          this.cafeReviews = data.reviews
        })
        .catch(() => {
          //silent, reviews are not so critical
        })
    },
    goToRegister () {
      // window.document.documentElement.scrollTop = document.getElementById(
      //   "register"
      // ).offsetTop;
      window.location.href = 'https://dashboard.qrwaiter.com.ua/register'
    },
    fetchPricing () {
      LandingAPI.getPricing()
        .then(data => {
          this.pricing = data.pricing
        })
        .catch(err => {
          console.error(err)
        })
    },
    checkRegister () {
      if (this.$route.params.key || this.$route.name == 'register') {
        this.$nextTick(() => {
          setTimeout(() => {
            window.document.documentElement.scrollTop = document.getElementById('register').offsetTop
          }, 500)
        })
      }
    }
  },

  computed: {
    currentCafeReview () {
      if (!this.cafeReviews.length) return {}
      return this.cafeReviews[this.selectedCafeReview]
    },
    featureIndex () {
      return this.featurePics.indexOf(this.currentFeatureImage)
    },
    youtubeLink () {
      const LINKS = {
        en: 'https://www.youtube.com/embed/UYhumdYVHYQ?rel=0',
        ru: 'https://www.youtube.com/embed/uOl-3DlEJbc?rel=0',
        ua: 'https://www.youtube.com/embed/hY7djhir0L4?rel=0'
      }

      return LINKS[this.$i18n.locale]
    }
  },
  watch: {
    mode () {
      if (this.mode === 'visitors') this.$router.push('/visitor')
    },
    '$route.name' () {
      this.checkRegister()
    }
  },
  mounted () {
    this.currentFeatureImage = this.featurePics[0]
    this.fetchPricing()

    window.addEventListener('scroll', this.updateScroll)

    this.resetPageScroll()

    this.fetchReviews()

    this.checkRegister()
  },
  components: {
    'demo-popup': demoPopup,
    ImagesSwitcher,
    Register,
    Navbar,
    Footer,
    Plans,
    Button
    // TabSwitcher,
  }
}
</script>

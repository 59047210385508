import Vue from 'vue';
import App from './App.vue';
import router from './router';

import VueI18n from 'vue-i18n';

import i18nMessages from './i18n';

Vue.config.productionTip = false;

import { VueMaskDirective } from 'v-mask';
Vue.directive('mask', VueMaskDirective);

import InputFacade from 'vue-input-facade';
Vue.use(InputFacade);

import UIkit from '@qrwaiter/qrwaiter-ui-kit';

import '@qrwaiter/qrwaiter-ui-kit/dist/qrwaiter-ui-kit.css';

Vue.use(VueI18n);
Vue.use(UIkit);

Vue.mixin({
  methods: {
    resetPageScroll() {
      window.document.documentElement.scrollTop = 0;
    },
  },
});

let default_locale = window.localStorage.getItem('locale') || 'ua';

if (!i18nMessages[default_locale]) {
  default_locale = 'ua';
}

const i18n = new VueI18n({
  locale: default_locale,
  messages: i18nMessages,
});
new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');

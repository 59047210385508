import Base from '../base'

function loginAdmin(email, password) {
    return Base.post('/auth/login/admin', {
        email,
        password
    })
}

function loginPersonalQueue(qId, pin) {
    return Base.post(`/auth/login/waiter`, {
        qId,
        pin
    });
}

function loginSharedQueue(qId) {
    return Base.post(`/auth/login/shared`, {
        qId
    });
}

function loginAdminTwoFactor(token, code) {
    return Base.post(`/auth/login/admin/2fa`, {
        token,
        code
    });
}

function forgotPassword(email) {
    return Base.post(`/auth/forgot_password`, {
        email
    });
}

function resetPassword(token, password) {
    return Base.post(`/auth/forgot_password/${token}`, {
        password
    });
}

export default {
    loginAdmin,
    loginPersonalQueue,
    loginSharedQueue,
    loginAdminTwoFactor,
    forgotPassword,
    resetPassword
}
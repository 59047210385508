<template>
  <div class="register-wrapper">
    <h2>{{ $t("register.title") }}</h2>
    <img
      :src="stagerSrc"
      class="stager"
    />

    <div
      class="registr-box"
      v-if="stage == 0"
    >
      <div class="info pos">
        <h5>{{ $t("register.reg") }}</h5>
        <p>{{ $t("register.info") }}</p>

        <div class="poster-signup">
          <p class="poster-desc">{{ $t("register.poster_desc") }}</p>
          <button
            class="poster-button"
            @click="posterSignup"
            data-testid="poster_register_button"
          >
            <!-- <img src="/pics/features/poster.png" class="poster-icon"> -->
            {{ $t("register.poster_button") }}
          </button>
        </div>
      </div>
      <form @submit.prevent="onRegister">
        <input
          type="text"
          data-testid="cafe_name_input"
          :placeholder="$t('register.name')"
          v-model="register_form.cafe"
          required
        />
        <input
          type="text"
          id="gmapField"
          data-testid="address_input"
          autocomplete="off"
          :placeholder="$t('register.address')"
          :class="{
            'invalid-input': !validation.address,
          }"
          required
        />
        <select
          :class="{
          'disabled': !register_form.category
        }"
          v-model="register_form.category"
          data-testid="category_select"
        >
          <option
            :value="null"
            disabled
          >
            {{ $t('register.category_ph') }}
          </option>
          <option value="cafe">
            {{ $t('register.category_cafe') }}
          </option>
          <option value="hotel">
            {{ $t('register.category_hotel') }}
          </option>
        </select>
        <input
          type="text"
          :placeholder="$t('register.username')"
          :class="{
            'invalid-input': !validation.username,
          }"
          v-model="register_form.name"
          required
        />
        <input
          type="text"
          :placeholder="$t('register.role')"
          :class="{
            'invalid-input': !validation.role,
          }"
          v-model="register_form.role"
          required
        />
        <div class="phone-widget">
          <input
            type="text"
            data-testid="phone_input"
            id="phone_code_input"
            pattern="[0-9\-]*"
            autocomplete="tel"
            :class="{
              'invalid-input': !validation.phone,
            }"
            @blur="onPhoneEntered"
          />
          <!-- <input-facade
            type="text"
            placeholder="+38-(XXX)-XXX-XX-XX"
            autocomplete="tel"
            v-model="register_form.phone"
            :mask="'+38-(###)-###-##-##'"
            :masked="false"
            :class="{
              'invalid-input': !validation.phone
            }"
            required
          />-->
        </div>

        <input
          type="email"
          :placeholder="$t('register.email')"
          v-model="register_form.email"
          required
        />

        <div class="agreement">
          <div
            data-testid="terms_checkbox"
            :class="[
              terms_agreed ? 'checkbox active' : 'checkbox',
              validation.terms ? '' : 'invalid-input',
            ]"
            @click="terms_agreed = !terms_agreed"
          ></div>
          <small>{{ $t("register.read") }}</small>
        </div>

        <span
          class="confirm-error"
          v-if="register_error && !specialRegisterError"
        >{{ $t("register.failed") }} ({{ register_error }})</span>

        <button
          class="btn btn-yellow"
          type="submit"
          data-testid="register_button"
        >
          {{ $t("register.register") }}
        </button>

        <div
          class="account-exists"
          data-testid="account_exists"
          v-if="register_error && specialRegisterError == 'account_exists'"
        >
          {{ $t("register.account_exists") }}
          <button
            class="btn btn-green"
            type="submit"
            @click="loginExisting"
          >
            {{ $t("login.login") }}
          </button>
        </div>
      </form>
    </div>

    <div
      class="registr-box"
      v-if="stage == 1"
    >
      <div class="info">
        <h5>{{ $t("register.reg") }}</h5>
        <p>{{ $t("register.info") }}</p>
      </div>
      <div class="info">
        <p class="yellow">{{ $t("register.sent") }}</p>
        <p class="yellow">{{ $t("register.click") }}</p>
      </div>
    </div>
    <div
      class="registr-box"
      v-if="stage == 2"
    >
      <div class="info">
        <h5>{{ $t("register.great") }}</h5>
        <p>{{ $t("register.onlypass") }}</p>
      </div>

      <form
        @submit.prevent="onConfirmRegistration"
        v-if="register_key_valid"
      >
        <input
          type="password"
          v-model="confirm_form.password"
          :placeholder="$t('register.inputpass')"
          required
        />
        <input
          type="password"
          v-model="confirm_form.repeat_password"
          :placeholder="$t('register.repeat')"
          required
        />
        <input
          type="text"
          :placeholder="$t('register.role')"
          :class="{
            'invalid-input': !validation.role,
          }"
          v-model="confirm_form.role"
          required
          v-if="missing_fields.includes('role')"
        />
        <input
          type="text"
          id="gmapField"
          autocomplete="off"
          :placeholder="$t('register.address')"
          :class="{
            'invalid-input': !validation.address,
          }"
          v-if="missing_fields.includes('address')"
        />
        <div
          class="password-strength"
          v-if="confirm_form.password"
        >
          <span
            class="bad-password"
            v-if="passwordScore < 2"
          >{{
            $t("register.tooweak")
          }}</span>
          <span
            class="strong-password"
            v-else
          >{{ $t("register.good") }}</span>
        </div>
        <span
          class="confirm-error"
          v-if="confirm_form.error"
        >{{
          confirm_form.error
        }}</span>

        <button
          class="btn btn-yellow"
          type="submit"
        >
          {{ $t("register.create") }}
        </button>
      </form>
      <div
        class="confirm-error"
        v-if="!register_key_valid"
      >
        {{ $t("register.wrong") }}
      </div>
    </div>
    <div
      class="registr-box"
      v-if="stage == 3"
    >
      <div class="info">
        <h5>{{ $t("register.gooduse") }}</h5>
        <p>{{ $t("register.nessesary") }}</p>
        <a
          class="settings-link"
          href="https://dashboard.qrwaiter.com.ua/general"
        >
          <button class="btn btn-yellow">
            {{ $t("register.gotosettings") }}
          </button>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import GoogleMapsService from '@/services/googlemaps'

import JoinAPI from '@/api/join'

import zxcvbn from 'zxcvbn'

let itiInstance = null

export default {
  data() {
    return {
      stage: 0,

      register_form: {
        cafe: null,
        address: null,
        name: null,
        phone: null,
        email: null,
        category: null,
        city: null,
        address_location: null,
        country: null,
        language: 'ua',
        saleagent_id: null,
        manager_link: null
      },

      missing_fields: [],

      register_error: null,

      terms_agreed: false,

      validation: {
        address: true,
        phone: true,
        terms: true,
        username: true,
        role: true
      },

      register_key_valid: true,

      confirm_form: {
        error: null,
        password: null,
        repeat_password: null,
        weak_password: false,

        city: null,
        address: null,
        address_location: null,
        country: null
      },

      autocomplete_object: null,

      busy: false
    }
  },
  props: {
    register_key: {
      type: String,
      default: null
    }
  },
  methods: {
    onRegister() {
      if (this.busy) return

      this.validation.address = true

      if (!this.validation.phone) {
        return
      }

      if (!this.register_form.address) {
        this.validation.address = false
        return
      }

      if (this.register_form.cafe.toLowerCase().trim() == this.register_form.name.toLowerCase().trim()) {
        this.validation.username = false
        return
      }

      if (!this.register_form.role) {
        this.validation.role = false
        return
      }

      if (!this.terms_agreed) {
        this.validation.terms = false
        return
      }

      this.busy = true

      this.register_form.manager_link = this.$route.query.slid || null

      JoinAPI.apply(this.register_form)
        .then(() => {
          this.stage = 1
          this.busy = false

          window.fbq('track', 'Lead')
        })
        .catch(error => {
          this.register_error = error
          this.busy = false
        })
    },
    onPlaceChanged() {
      const result = GoogleMapsService.decodeAutocompletePlace(this.autocomplete_object)

      if (this.stage == 2) {
        this.confirm_form.address = result.address
        this.confirm_form.city = result.city
        this.confirm_form.address_location = result.location
        this.confirm_form.country = result.country
      } else {
        this.register_form.address = result.address
        this.register_form.city = result.city
        this.register_form.address_location = result.location
        this.register_form.country = result.country
      }

      console.log(result)

      itiInstance.setCountry(result.country.toLowerCase())
    },
    initAddressField() {
      const element = document.getElementById('gmapField')

      console.log(`gmapField: `, element)

      this.autocomplete_object = GoogleMapsService.initAutocompleteWidget(element, this.onPlaceChanged)
    },
    checkRegisterKey() {
      JoinAPI.check(this.register_key)
        .then(data => {
          this.missing_fields = data.missing_fields

          if (this.missing_fields.includes('address')) {
            setTimeout(() => {
              this.$nextTick(this.initAddressField)
            }, 1250)
          }
        })
        .catch(() => {
          this.register_key_valid = false
        })
    },
    loginExisting() {
      this.$router.push(`/login?email=${this.register_form.email}`)
    },
    onConfirmRegistration() {
      if (this.busy) return

      this.confirm_form.error = null

      const password_strength = zxcvbn(this.confirm_form.password).score

      this.confirm_form.weak_password = false

      if (password_strength < 2) {
        // this.confirm_form.error = "Пароль занадто короткий.";
        return
      }

      if (this.confirm_form.password != this.confirm_form.repeat_password) {
        this.confirm_form.error = 'Паролі не співпадають.'
        return
      }

      if (this.missing_fields.includes('address') && !this.confirm_form.address) {
        this.validation.address = false
        return
      }

      if (this.missing_fields.includes('role') && !this.confirm_form.role) {
        this.validation.role = false
        return
      }

      this.busy = true

      JoinAPI.confirm(this.register_key, {
        password: this.confirm_form.password,
        address: this.confirm_form.address,
        address_location: this.confirm_form.address_location,
        city: this.confirm_form.city,
        country: this.confirm_form.country,
        role: this.confirm_form.role
      })
        .then(() => {
          window.fbq('track', 'CompleteRegistration', {
            value: 0,
            currency: 'UAH'
          })
          this.stage = 3
          this.busy = false
        })
        .catch(() => {
          this.confirm_form.error = 'Не вдалось створити аккаунт.'
          this.busy = false
        })
    },
    initPhoneCodeInput() {
      const el = document.getElementById('phone_code_input')

      itiInstance = window.intlTelInput(el, {
        utilsScript: '/intl-tel-input/js/utils.js',
        initialCountry: 'ua',
        separateDialCode: true
      })
    },
    onPhoneEntered() {
      if (!itiInstance.isValidNumber()) {
        this.validation.phone = false
        return
      }

      this.register_form.phone = itiInstance.getNumber()
      this.validation.phone = true
    },
    posterSignup() {
      window.location.href = '/api/poster/start'
    }
  },
  mounted() {
    if (this.register_key) {
      this.stage = 2
      this.checkRegisterKey()
      return
    }

    setTimeout(() => {
      this.$nextTick(this.initAddressField)
      this.$nextTick(this.initPhoneCodeInput)
    }, 3500)
  },
  computed: {
    stagerSrc() {
      let real_stage = this.stage || 1

      return `/icons/stager-${real_stage}.svg`
    },
    passwordScore() {
      if (!this.confirm_form.password) return 0

      return zxcvbn(this.confirm_form.password).score
    },
    specialRegisterError() {
      if (this.register_error == 'login_invalid_data') {
        return 'account_exists'
      }

      return null
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Chadwick', Arial, Helvetica, sans-serif;
}

.register-wrapper {
  height: 115vh;

  position: relative;
  z-index: 3;
  width: 100%;
  background: #1d1d1d;
  background-image: url('/icons/big-qr.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: 65vh;
}

h2 {
  font-size: 7vh;
  font-weight: 500;
  color: #464646;
  padding: 10vh 19vh 0;
  text-transform: uppercase;
}

img.stager {
  width: auto;
  margin: -2vh auto;
  display: block;
  height: 9.5vh;
}

.registr-box {
  padding: 10vh 12vh 0;
  color: #ededed;
  display: flex;
  justify-content: space-between;
}

.registr-box h5 {
  color: #908f8d;
  font-size: 4vh;
  font-weight: 500;
}

.registr-box p {
  width: 50vh;
  font-size: 2.3vh;
  padding-top: 3.3vh;
}

.info.pos {
  display: flex;
  flex-flow: column;
  position: relative;
}

form {
  width: min-content;
  margin-left: 20vh;
}

form input {
  display: block;
  font-size: 3vh;
  padding: 1vh;
  width: 100%;
  border: none;
  outline: none;
  background: #000000;
  margin-bottom: 2.2vh;
  color: #ededed;
}

form select {
  display: block;
  font-size: 3vh;
  padding: 1vh;
  width: 100%;
  border: none;
  outline: none;
  background: #000000;
  margin-bottom: 2.2vh;
  color: #ededed;
  -webkit-appearance: none;
}

form select.disabled {
  color: #908f8d;
}

.agreement {
  padding-top: 1.2vh;
  display: flex;
}

.agreement small {
  color: #908f8d;
  font-size: 1.3vh;
  padding-left: 2vh;
  display: block;
}

.checkbox {
  height: 2.7vh;
  width: 3.3vh;
  cursor: pointer;
  background: #ededed;
  border: 2px solid #ededed;
}

.checkbox.active {
  background-color: black;
}

.registr-box h5 {
  color: #908f8d;
  font-size: 4vh;
  font-weight: 500;
}

.registr-box p {
  width: 50vh;
  font-size: 2.3vh;
  padding-top: 3.3vh;
}

form {
  width: 49.5vh;
}

form input {
  display: block;
  font-size: 3vh;
  padding: 1vh;
  width: 100%;
  border: none;
  outline: none;
  background: #000000;
  margin-bottom: 2.2vh;
  color: #ededed;
}
.btn {
  width: 50vh;
  display: block;
  margin-top: 9vh;
  cursor: pointer;
  background: transparent;
  outline: none;
  transition: 0.3s;
  padding: 1.3vh 0;
  border: none;
  font-size: 2.3vh;
}
.btn-yellow {
  margin-top: 6.6vh !important;
  background: #ffd2a8 !important;
}

p.yellow {
  color: #ffd2a8;
}

.invalid-input {
  border: 1px solid #e2223b;
}

.confirm-error {
  color: #e2223b;
}

.bad-password {
  color: #e2223b;
}

.strong-password {
  color: #129b81;
}

.settings-link {
  text-decoration: none;
}

.phone-widget {
  margin-bottom: 2.2vh;
}

.poster-signup {
  display: flex;
  flex-flow: column;
  bottom: 0;
  position: absolute;
}

.poster-desc {
  color: #908f8d;
}

.poster-icon {
  display: inline-block;
  width: 4vh;
}

.poster-button {
  width: 50vh;
  display: block;
  margin-top: 2vh;
  cursor: pointer;
  outline: none;
  transition: 0.3s;
  padding: 1.3vh 0;
  border: none;
  font-size: 2.3vh;
  color: #ededed;
  background: #464646;
}

.account-exists {
  position: absolute;
  color: #e2223b;
  margin-top: 1vh;
}

.btn-green {
  margin-top: 1vh !important;
  background: #129b81;
  color: #ededed;
}

@media screen and (max-width: 768px) {
  .register-wrapper {
    height: auto !important;
    padding: 12vh 0;
  }
  .registr-box {
    padding: 2.5vh 2.5vh 0;
    flex-flow: column wrap;
  }
  .registr-box h5 {
    width: 60%;
    font-size: 3.7vh;
  }
  .registr-box p {
    width: 100%;
    font-size: 2.5vh;
  }
  form {
    width: 100%;
    margin: 3.7vh 0 0;
  }
  .btn-yellow {
    margin: 6vh auto 0 !important;
    width: 100%;
  }
  form .btn {
    width: 100%;
  }
  .checkbox {
    width: 40px;
    height: 26px;
  }

  img.stager {
    width: 89%;
    margin: 0 auto;
  }

  .register-wrapper h2 {
    font-size: 3.7vh;
    padding: 5vh 2.5vh 0;
  }
  .agreement small {
    font-size: 3vw;
  }

  .poster-signup {
    display: none;
  }
}
</style>
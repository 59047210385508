function decodeAutocompletePlace(gmap_autocomplete) {
    const place = gmap_autocomplete.getPlace();

    const components = place.address_components;

    let city_component = null;

    for (let c = 0; c < components.length; c++) {
        if (components[c].types.includes('locality')) {
            city_component = components[c];
        }
    }

    if (!city_component) {
        city_component = components.find(c => c.types.includes('locality') || c.types.includes('political'));   
    }

    // const city_component = components.find(c => c.types.includes('locality') || c.types.includes('political'));
    const country_component = components.find(c => c.types.includes('country'));

    return {
        address: place.formatted_address,
        location: {
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng()
        },
        city: city_component.long_name,
        country: country_component.short_name
    }

    // const street_address_component = components.find(c => c.types.includes('street_address'));
    // const route_component = components.find(c => c.types.includes('route'));
    // const city_component = components.find(c => c.types.includes('locality') || c.types.includes('political'));
    // const street_number_component = components.find(c => c.types.includes('street_number'));

    // const street_address = street_address_component ? street_address_component.long_name : '';
    // const route = route_component ? route_component.long_name : '';
    // const city = city_component ? city_component.long_name : '';
    // const street_number = street_number_component ? street_number_component.long_name : '';

    // const parts = [];

    // if (street_address) parts.push(street_address);
    // if (route) parts.push(route);
    // if (street_number) parts.push(street_number);
    // if (city) parts.push(city);

    // if (parts.length < 3) return null;

    // return parts.join(', ');
}

function initAutocompleteWidget(el, callback) {
    const autocomplete = new window.google.maps.places.Autocomplete(
        el,
        { types: ["geocode"] }
    );

    autocomplete.setFields(["address_components", "formatted_address", "geometry"]);

    autocomplete.addListener("place_changed", callback);

    return autocomplete;
}

export default {
    initAutocompleteWidget,
    decodeAutocompletePlace
}
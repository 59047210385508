export default {
    landing: {
        see: `It is better to see once`,
        how: `How does it work?`,
        guests: `Your guests can access the digital menu using QR codes at cafe
        and links in your social networks`,
        app: `Or with the help of the QR application`,
        stickers: `QR-STICKERS ON TABLES`,
        design: `Designer stickers,
        which won't leave anyone indifferent
        and will attract attention.`,
        waterproof: `Waterproof. Durable.`,
        admin: `You can order stickers at Admin Panel of cafe`,
        algorythm: `Algorithm of personalized smart recommendations, which will advise what to order to each guest.`,
        pay3: `3 WAYS TO PAY`,
        visitorspay: `Your guests can always choose a comfortable way to pay for order.`,
        pay: `Online. In cash. Terminal.`,
        liqpay: `Online payment works using LiqPay`,
        multilangtitle: `MULTILINGUAL MENU`,
        multilang: `The language barrier has been successfully destroyed!
        The menu is displayed in all languages.`,
        autotranslate: `The system automatically translates menu into language of guest's device.`,
        quicktitle: `QUICK ACCESS`,
        quick1: `You do not need to download application and enter many passwords to order coffee.`,
        quick2: `All you have to do is open camera and point it to QR code.`,
        comfortabletitle: `USER-FRIENDLY INTERFACE`,
        comfortable1: `Obviously, the paper menu can not contain all the descriptions, prices and modificators for dishes.`,
        comfortable2: `What about the light and dark interface, which automatically switches during the day?`,
        comfortable3: `The paper menu can't do that.`,
        tabletitle: `TABLE RECOGNITION`,
        table1: `Our system is able to automatically recognize from which table order was made.`,
        table2: `More than just a link to a site.`,
        servicestitle: `SERVICES`,
        services1: `There are things that cannot be digitized. So far it's impossible.`,
        services2: `Bring cutlery, clean the table, call the waiter...`,
        services3: `- all these services your guests can order in 1 click.`,
        ez1: `ACCEPTING ORDERS`,
        ez2: `HAS NEVER BEEN THAT`,
        ez3: `SIMPLE`,
        ez4: `Order at cafe,
        delivery, pre-order
        or even table reservations`,
        ez5: `- all information in one application for staff`,
        postitle: `POS INTEGRATIONS`,
        pos1: `POSTER INTEGRATION`,
        pos2: `With our partners, Poster, we have done deep two-way integration.
        This means that any changes to our system are automatically displayed online in the Poster system.`,
        pos3: `And you can accept orders directly from the Poster terminal.`,
        marketplace: `<span>APPS</span> ios | android`,
        marketplace1: `In addition to local systems, you can place your institution in our marketplace`,
        more: `Read more`,
        watch: `View the marketplace`,
        partners: `OUR PARTNERS`,
        partners1: `More than 100 cafes are already with us!`,
        review: `Feedback from`,
        intro: {
            header: 'DIGITAL MENU<span>for your cafe</span>',
            contact:'Contact us',
            action:'Connect for free'
        }
    },
    prices: {
        inside_lite: {
            title: 'Digital menu for your cafe.',
            subtitle: `Nothing more, just necessary information. You do not need to install the application for staff, and connect the integration. It's just a menu, like paper one, but digital.`,
        },
        inside: {
            title: '"If you need to digitize the ordering process - then digitize whole process!", we thought and made INSIDE system.',
            subtitle: 'Your guests can not only view photos of dishes and prices, but also easily make an order, leave a comment, leave tips and pay. Even when waiter not nearby - digital menu at your fingertips!',
            ai_text: `By the way, QRWaiter AI does extra
             sales automatically, and can't
             have a bad mood)`,
        },
        preorder: {
            title: 'Increase potential of your cafe!',
            subtitle: `Increase the potential of your own cafe!
             You don't have to rent extra space and increase number of tables - you can just connect PREORDER system.`,
            subtitle2: `Your guests can order "takeaway" or book a table. That's must- have for rush hour on a weekday.`
        },
        delivery: {
            title: 'Want to start your own delivery? We will help!',
            subtitle: ` All you need to do is connect the DELIVERY system.
             Accept delivery orders, which already contains all the necessary information. Guest name, phone number, where to deliver and at what time.`,
            subtitle2: 'All you have to do is cook and send order.',
        },
        reserve: {
            title: 'Table reservations are already digitized!',
            subtitle: 'Your guests can book a table in 2 clicks.',
            subtitle2: `It's faster than calling to cafe.`,
            subtitle3: 'You can specify a maximal number of people and a minimal deposit.'
        },
    },
    pricing: {
        title: 'QRWaiter Solutions',
        monthly: 'Pay monthly',
        yearly: 'Pay anually',

        month: 'month',
        year: 'year',
        preview_price:'FREE',
        preview_desc: 'Digital menu for guests without ordering features',
        preview_how: 'System works via online link and QR-codes on tables',

        connect_free: 'Connect for free',
        connect_for: 'Connect for',
        try_it: 'Try',
        freemium_limit: 'Trial period 14 days',
        save: 'Save',

        delivery_desc: 'Digital menu for guests for delivery orders',
        delivery_how: 'System works via online link',

        takeaway_desc: 'Digital menu for guests for takeaway orders',
        takeaway_how: 'System works via online link',

        inside_desc: 'Digital menu for ordering inside your place',
        inside_how: 'System works via QR-codes on tables'
    },
    page_switcher: {
        for_cafes: 'FOR BUSINESS',
        for_users: 'FOR GUESTS'
    },
    demo: {
        mobile: `This page is only available on the mobile version. Scan the QR code to open the mobile version of the site.`
    },
    login: {
        back: `WELCOME BACK!`,
        admin: `Admin panel of cafe`,
        queue: `Order queue`,
        log: `Your login`,
        password: `Your password`,
        login: `Log in`,
        failed: `Login failed. Check login and password.`,
        num: `To your number`,
        sent: `an SMS with the code was sent.`,
        confirm: `Confirm`,
        personal: `Personal`,
        shared: `Shared`,
        id: `cafe ID`,
        pin: `Your pin code`,
        orders: `To orders ->`,
        failedid: `Login failed. Check that the cafe ID and PIN are correct.`,
        forgot_password: 'Forgot password?',
        forgot_back: 'Back',
        forgot_email_ph: 'Your email address',
        forgot_btn: 'Restore access',
        forgot_sent: 'If email address was specified corretly, we have sent an email with instructions to restore access to your account.'
    },
    marketplace: {
        auditory: `THE AUDIENCE OF ALL CAFES CAN BECOME YOURS!`,
        order: `order`,
        reserve: `reserve`,
        increase: `Grow your audience with the QRWaiter marketplace.`,
        city: `Cities where the marketplace works`,
        todo: `To add your cafe to marketplace, you need to create a QRWaiter account. If your cafe is located in a city where the marketplace works, you will automatically get access to the marketplace section of settings.`
    },
    privacy: {
        title: `Політика конфіденційності`,
        general: `1. Загальні положення`,
        pt1: `Умови даної політики конфіденційності росповсюджуються на всі сторінки веб-сайту
        QRWaiter, який працює за посиланням qrwaiter.com.ua. Користувач сайту повинен
        бути згодним з умовами ціїє політики для того, щоб користуватись сайтом.
        Користуючись сайтом, користувач надає згоду на збір та обробку його особистих
        даних згідно з цією політикою конфіденційності.`,
        info: `2. Збір інфромації`,
        pt2: `Адміністрація сайту QRWaiter збирає про користувача лише ту інформацію, яку він сам надає. Це може здійснюватись за
        домогою декількох способів, а саме:`,
        pt3: `Безпосередня передача особистої інформації за допомогою форми реєстрації, форми зворотнього
        зв’язку або
        будь-якої іншої форми на сайті, яка потребує безпосерднього вводу інформації користувачем`,
        pt4: `Cookies-файли браузера, який використовує користувач для переглядання і використання веб-сайту
        QRWaiter`,
        pt5: `Технологія Local Storage в браузері користувача`,
        pt6: `Технологія Geolocation API`,
        pt7: `Інші технічні протоколи передачі інформації (http, TCP), які використовуються браузером
        користувача`,
        pt8: `Інформація, яку ми збираємо, може включати:`,
        pt9: `Адресу електронної пошти користувача`,
        pt10: `Мобільний телефон користувача`,
        pt11: `IP-адрес, з якого користувач здійснив вхід на сайт QRWaiter`,
        pt12: `Назву та версію браузера, який використовує користувач`,
        pt13: `Геолокаційні координати користувача, які надаються тільки за його згодою.`,
        pt14: `Прізвище ім’я та по-батькові користувача.`,
        pt15: `Адміністрація сайту QRWaiter не несе відповідальність за достовірність, правильність і своєчасність
        інформації, яку
        надає користувач.`,
        goal: `3. Цілі збирання інформації`,
        pt16: `Адміністрація QRWaiter збирає особисту інформацію користувача для:`,
        pt17: `Ідентифікацї користувача на сайті QRWaiter`,
        pt18: `Покращення роботи сайту та послуг`,
        pt19: `Створення анонімних статистичних звітів для аналізу`,
        pt20: `Зв’язку з користувачем для вирішення питань, пов'язаних з роботою сайту та сервісу QRWaiter`,
        pt21: `Проведення розіграшів та конкурсів`,
        security: `4. Безпека особистих даних`,
        pt22: `Особиста інформація користувачів захищена усіма можливими технічними методами. Адміністрація сайту не передає особисту
        інформацію третім особам за будь-яких обставин, окрім випадків, передбачених чинним законодавством України, а саме у
        випадку офіційного запиту на отримання доступу до особистої інформації користувачів від уповноважених органів влади.
        Також при проведенні конкурсів та розіграшів QRWaiter може передавати прізвище ім’я та по-батькові, мобільний телефон чи
        адресу електронної пошти користувача Товариству з обмеженою відповідальністю «Мобізон» виключно для верифікації даних
        користувача в автоматизованому режимі.
        Окремо зазначаємо, що ознайомитися з політикою конфіденційності ТОВ «Мобізон» користувачі можуть за посиланням:`,
        pt23: `Особисті дані користувача зберігаються на серверах QRWaiter до тих пір, поки не буде видалений обліковий запис даного
        користувача. Особиста інформація користувача може бути видалена або змінена за запитом цього ж користувача.`,
        preend: `5. Прикінцеві положення`,
        pt24: `Ця політика конфіденційності розташовується в мережі Інтернет за постійним посиланням (`,
        pt25: `Адміністрація може змінити зміст Політики конфіденційності в будь-який момент часу без попереднього
        попередження.
        Нова політика конфіденційності вступає в силу з моменту її опублікування.`,
        pt26: ` З будь-яких питань, пов'язаних з цією Політикою конфіденційності, а також з будь-яких інших
        питань, що
        стосуються QRWaiter або Сервісу, звертайтеся до нас:
        за поштовою адресою`,
        tg: `або Telegram каналом -`,
        end: `Останнє оновлення: 11/07/2020
        АВТОРСЬКЕ ПРАВО. 2019-2023 QRWaiter`
    },
    register: {
        title: `How to connect?`,
        reg: `Register your cafe`,
        info: `Enter general information and leave your contacts so we can contact you and help set up everything if necessary.`,
        name: `Name of cafe`,
        address: `Address of the institution`,
        username: `Your name`,
        role: 'Your company position',
        email: `Your email address`,
        read: `I have read and agree to the terms of use and privacy policy.`,
        failed: `An error occurred while registering`,
        register: `Register`,
        sent: `A confirmation email has been sent to your email.`,
        click: `Click on link in letter.`,
        great: `Great!`,
        onlypass: `Now just create a password for your account.`,
        inputpass: `Enter password`,
        repeat: `Repeat password`,
        tooweak: `Password too weak.`,
        good: `Strong password.`,
        create: `Create an account`,
        wrong: `Invalid or expired link.`,
        gooduse: `Enjoy!`,
        nessesary: `Now you can enter the menu and configure the necessary systems.`,
        gotosettings: `Go to settings!`,
        poster_desc: 'You can also create account in few clicks, if you have an account in Poster',
        poster_button: 'Signup with Poster',
        contact_info:`Leave your contacts and we will call you`,
        contact_sent:`We will call you during the day`,
        contact_btn: 'Send request',
        goback:`Go back to main page`,
        account_exists: 'Account with such email already exists.',

        category_ph: 'QRWaiter system type',
        category_cafe: 'For cafes',
        category_hotel: 'For hotels',
    },
    terms: {
        title: `Умови використання`,
        warn: `Увага! Перед використанням даного сайту уважно прочитайте та ознайомтесь з цими умовами.`,
        confirm: `Ухвалення умов`,
        pt1: `Умови використання («Умови»), які ви читаєте, є юридично зобов'язуючою угодою між QRWaiter. (
            «QRWaiter», «ми» )
            і Вами («Ви»). Отримуючи доступ, і використовуючи веб-сайт або Систему(“Систему)” QRWaiter, Ви
            погоджуєтеся з
            тим, що прочитали, зрозуміли, приймаєте та погоджуєтесь дотримуватися цих Умов. Якщо Ви не згодні з
            цими
            Умовами, не використовуйте Послуги(“Послуги”).`,
        pt2: `QRWaiter залишає за собою право на свій розсуд переглядати або змінювати ці Умови в будь-який час, і
        Ви
        погоджуєтеся з такими змінами. Користувачі несуть відповідальність за перегляд цих Умов періодично.
        Ваше
        подальше використання Сервісу після внесення змін до цих Умов буде означати вашу згоду з
        переглянутими Умовами.
        Якщо Ви не згодні з Умовами, ваше єдиний засіб правового захисту - припинити використання Послуг та
        анулювати
        будь-які облікові записи, які Ви створили для використання Послуг.`,
        rights: `Права Компанії`,
        pt3: `Компанія залишає за собою право: Змінити Сайт на свій розсуд / Змінювати вартість і види послуг,
        терміни їх дії.`,
        pt4: `Інформація про реєстрацію`,
        pt5: `Здійснюється на сайті за допомогою форми реєстрації.`,
        pt6: `Ви не повинні ділитися своїм обліковим записом або реєстраційної інформацією, а також не
        дозволяти
        кому-небудь отримувати доступ до Вашого облікового запису або робити що-небудь ще, що може
        поставити під
        загрозу безпеку Вашого облікового запису.`,
        pt7: `У разі, якщо вам стало відомо або підозрюєте будь-яке порушення безпеки, будь-яку втрату,
        крадіжку або
        розкриття вашої реєстраційної інформації або доступ до вашого облікового запису, Ви повинні
        негайно
        повідомити QRWaiter і змінити свою реєстраційну інформацію.`,
        pt8: `Ви також несете особисту відповідальність за всі дії під вашим логіном та паролем на сайті
        “qrwaiter.com.ua”.`,
        pt9: `Ви погоджуєтесь контролювати свій обліковий запис і обмежувати використання будь-якою особою. Ви
        приймаєте
        на себе повну відповідальність за будь-яке незатверджене використання Послуг.`,
        pt10: `Право інтелектуальної власності`,
        pt11: `QRWaiter зберігають за собою всі права на матеріали Сервісу (включаючи, програмне забезпечення,
            дизайн, графіку,
            тексти, інформацію, зображення, відео, звук, музику та інші файли. Весь вміст Сервісів захищено
            відповідними
            законами про авторське право (Відомості Верховної Ради України (ВВР), 1994, № 13, ст.64 цивільного
            кодексу
            України)`,
        rules: `Правила Використання`,
        pt12: ` Ви зобов'язуєтеся не обманювати QRWaiter або інших користувачів при використанні Послуг. Якщо
        QRWaiter визначить,
        що Ви порушили ці Умови, QRWaiter може, на власний розсуд, закрити ваш обліковий запис і заборонити
        вам
        використовувати Сервіс. Ви погоджуєтеся з тим, що використання вами Послуг має бути законним і що Ви
        будете
        дотримуватися правил використання. Ви погоджуєтеся, що Ви не повинні:`,
        pt13: `Копіювати, продавати, передавати, призначати, встановлювати права, аналізувати код, здійснювати
        зворотний
        інжиніринг.`,
        pt14: `Завантажувати або передавати файли, які містять віруси, пошкоджені файли або дані, або будь-яке
        інше
        програмне забезпечення, які можуть пошкодити роботу Сервісу.`,
        pt15: `Намагатися отримати паролі або іншу особисту інформацію від інших учасників, включаючи
        інформацію, яка
        дозволяє встановити особу.`,
        pt16: `Неправильне використання каналів підтримки для створення помилкових звітів в QRWaiter.`,
        pt17: `Брати участь в шахрайських діях.`,
        pt18: `Намагатися втручатися, зламувати або розшифровувати будь-які передачі на або з серверів.`,
        pt19: `Втручатися в здатність інших користуватися Сервісом, в тому числі переривати роботу,
        перевантажувати
        сервери QRWaiter, або вживати дії, які заважають для користування всіма їх користувачами.`,
        pt20: `З будь-яких питань, пов'язаних з цими Умовами використання, а також з будь-яких інших питань, що
        стосуються
        QRWaiter або Сервісу, звертайтеся до нас:
        за поштовою адресою`,
        tg: `або Telegram каналом - @QRWaiter.`,
        end: `Останнє оновлення: 11/07/2020
        АВТОРСЬКЕ ПРАВО. 2019-2023 QRWaiter`
    },
    visitors: {
        title: `YOUR FAVORITE CAFES`,
        forusers: `for Users`,
        forcafes: `for Cafes`,
        inside: `Make an order in cafe using QR-code`,
        preorder: `Order food
        beforehand`,
        delivery: `Order meals
        with delivery`,
        reserve: `Book a table for an important date`,
        download: `Download the application`,
        scan: `Scan the QR code`
    },
    password_recovery: {
        title: 'RESTORE ACCESS',
        fail: 'Password change failed. Check link validity.',
        success: 'Password has been successfully changed.',
        login: 'Login',
        confirm: 'Change password',

        desktop_only: 'Access restore is not available on mobile devices.',

        pass_ph: 'Enter new password',
        repeat_ph: 'Repeat new password'
    },
    navbar: {
        login: `Login`,
        register: `Register`
    },
    footer: {
        confidential: `Privacy Policy`,
        terms: `Terms of use`,
        offert: `Public offert`
    },
    contact_title:'Let`s stay it touch!'
}

<template>
  <div id="app">
    <router-view />

  </div>
</template>

<script>

export default {
  name: 'App',
  mounted () {
    window.document.body.style.backgroundColor = '#1d1d1d'

    window.Intercom('boot', {
      app_id: 'rsh7gloh'
    })
  }
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
}
</style>

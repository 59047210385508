<template>
  <div>
    <DemoPopup class="slot-box" v-if="demoType == 'client'">
      <h2 slot="header">C-DEMO</h2>
      <p
        slot="info"
      >{{$t('demo.mobile')}}</p>
      <img slot="hero" src="/icons/hero/sad.svg" class="hero" />
      <img slot="qr-pic" class="qr-pic" src="/pics/app-demo-qrcode.png" />
    </DemoPopup>

    <DemoPopup class="slot-box" v-if="demoType == 'waiter'">
      <h2 slot="header">W-DEMO</h2>
      <p
        slot="info"
      >{{$t('demo.mobile')}}</p>
      <img slot="hero" src="/icons/hero/hello.svg" class="hero hello" />
      <img slot="qr-pic" class="qr-pic" src="/pics/queue-demo-qrcode.png" />
    </DemoPopup>

    <DemoPopup class="slot-box" v-if="demoType == 'marketplace'">
       <h2 slot="header">MARKETPLACE</h2>
      <p
        slot="info"
      >{{$t('demo.mobile')}}</p>
      <img slot="hero" src="/icons/hero/Wine.svg" class="hero wine" />
      <img slot="qr-pic" class="qr-pic" src="/pics/marketplace-demo-qrcode.png" />
    </DemoPopup>
  </div>
</template>

<script>
import demoPopup from "@/components/demoPopup.vue";

export default {
  components: {
    DemoPopup: demoPopup
  },
  computed: {
    demoType() {
      return this.$route.params.type;
    },
    isMobile() {
      return window.innerWidth < 550;
    },
  }
};
</script>

<style scoped>


.slot-box {
  padding: 2vh 5vh;
}

.slot-box h2 {
  font-size: 8vh;
  position: relative;
  right: 11.3vh;
  text-align: right;
  color: #464646;
  top: 10vh;
  padding-bottom: 0;
}

.slot-box img.hero {
  height: 20vh;
  width: auto;
  position: absolute;
  left: 20.5vh;
  bottom: 19vh;
}

img.qr-pic {
  height: 40vh;
  width: auto;
  position: absolute;
  right: 30vh;
  bottom: 19vh;
}

.hero.wine {
  height: 30vh !important;
  left: 14vh !important;
}

.hero.hello {
  height: 25vh !important;
  
}

.w-100 {
  width: 100% !important;
}
</style>
<template>
  <div class="tabs-switcher" :class="themeBg">
    <div
      :class="{
        tab: true,
        'tab-active': tab.value == value && themeBg !== 'dark',
        'tab-active-dark': themeBg == 'dark' && tab.value == value,
      }"
      v-for="tab in tabs"
      :key="tab.value"
      @click="$emit('input', tab.value)"
    >
      <span
        class="qrw-button-text"
        v-html="tab.text"
        v-if="htmlRender"
      ></span>
      <span class="qrw-button-text" v-else> {{ tab.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: Array,
    value: String,
    page: String,
    theme: String,
    htmlRender: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    themeBg() {
      return this.theme == "dark" ? "dark" : "";
    },
  },
};
</script>

<style scoped>
.tabs-switcher {
  display: flex;
  border-radius: 4px;
  width: fit-content;
  background: #ededed;
  color: #908f8d;
  overflow: hidden;
}

.tabs-switcher.dark {
  background: #2e2e2e;
  color: #908f8d;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  width: 50%;
}

.tab-active-dark {
  background: #ededed;
  font-family: "ChadwickBold";
  font-weight: bold;
  color: #1f1f1f;
}

.tab-active {
  background: #464646;
  color: #ededed;
}

@media screen and (max-width: 400px) {
  .tab {
    padding: 5px 14px;
  }
}
</style>
<template>
  <div class="login-wrapper">
    <nav>
      <img src="/icons/qr-white-logo.svg" alt="logo" @click="go2Main()" />
    </nav>
    <h2>
      {{
        forgot_password.active
          ? $t("password_recovery.title")
          : $t("login.back")
      }}
    </h2>
    <main>
      <div>
        <span
          :class="adminPanel ? 'login-mode active' : 'login-mode mode-admin'"
          @click="adminPanel = true"
          >{{ $t("login.admin") }}</span
        >
        <span
          :class="!adminPanel ? 'login-mode active' : 'login-mode mode-queue'"
          @click="adminPanel = false"
          >{{ $t("login.queue") }}</span
        >
      </div>

      <form
        class="login-form admin-panel"
        @submit.prevent="loginAdmin"
        v-if="adminPanel && !two_factor.token && !isMobile"
      >
        <div v-if="!forgot_password.active">
          <input
            type="email"
            v-model="admin_form.email"
            class="login"
            :placeholder="$t('login.log')"
            required
          />
          <input
            type="password"
            v-model="admin_form.password"
            :placeholder="$t('login.password')"
            required
          />
          <a
            href="#"
            @click.prevent="showForgotUI"
            class="forgot-password-link"
          >
            {{ $t("login.forgot_password") }}
          </a>
          <button class="btn btn-yellow">{{ $t("login.login") }}</button>
          <div class="login-error" v-if="error">{{ $t("login.failed") }}</div>
        </div>
        <div v-else>
          <form @submit.prevent="sendRestore" v-if="!forgot_password.sent">
            <input
              type="email"
              v-model="forgot_password.email"
              class="login"
              :placeholder="$t('login.forgot_email_ph')"
              required
            />
            <button type="submit" class="btn btn-yellow">
              {{ $t("login.forgot_btn") }}
            </button>
          </form>
          <div class="forgot-password-sent" v-if="forgot_password.sent">
            {{ $t("login.forgot_sent") }}
          </div>
          <a
            href="#"
            @click.prevent="forgot_password.active = false"
            class="forgot-password-link"
            >&lt; {{ $t("login.forgot_back") }}</a
          >
        </div>
      </form>

      <form class="admin-panel" v-if="adminPanel && isMobile">
        <div class="mobile-admin-panel">
          Панель адміністратора доступна тільки на комп'ютерах.
        </div>
      </form>

      <form
        class="login-form admin-panel"
        @submit.prevent="loginTwoFactor"
        v-if="two_factor.token"
      >
        <input-facade
          :mask="'######'"
          type="text"
          :class="{ login: true, 'input-error': !!error }"
          placeholder="XXXXXX"
          v-model="two_factor.code"
          required
        />
        <p>
          {{ $t("login.num") }} ({{ two_factor.phone_mask }})
          {{ $t("login.sent") }}
        </p>
        <button class="btn btn-disable">{{ $t("login.confirm") }}</button>
      </form>

      <form
        class="login-form queue-panel"
        @submit.prevent="doQueueLogin"
        v-if="!adminPanel"
      >
        <div
          :class="personalQueue ? 'tab active' : 'tab'"
          @click="personalQueue = true"
        >
          {{ $t("login.personal") }}
        </div>
        <div
          :class="!personalQueue ? 'tab active' : 'tab'"
          @click="personalQueue = false"
        >
          {{ $t("login.shared") }}
        </div>
        <div v-if="personalQueue">
          <input
            type="text"
            v-model="queue_form.qId"
            class="login"
            :placeholder="$t('login.id')"
            required
          />
          <input
            type="password"
            maxlength="4"
            v-model="queue_form.pin"
            :placeholder="$t('login.pin')"
            required
          />
        </div>

        <input
          v-if="!personalQueue"
          v-model="queue_form.qId"
          type="text"
          class="login"
          :placeholder="$t('login.id')"
          required
        />

        <button class="btn queue btn-green" type="submit">
          {{ $t("login.orders") }}
        </button>
        <div class="login-error" v-if="error">{{ $t("login.failedid") }}</div>
      </form>
    </main>
    <a href="https://t.me/QRWaiter" class="support">Support</a>
  </div>
</template>

<script>
import AuthAPI from "@/api/auth";

export default {
  data() {
    return {
      adminPanel: true,
      personalQueue: true,

      forgot_password: {
        active: false,
        email: null,
        busy: false,
        sent: false,
      },

      two_factor: {
        token: null,
        phone_mask: null,
        code: null,
      },

      admin_form: {
        email: null,
        password: null,
      },

      queue_form: {
        qId: null,
        pin: null,
      },

      error: null,
    };
  },
  methods: {
    go2Main() {
      this.$router.push("/");
    },
    loginAdmin() {
      AuthAPI.loginAdmin(this.admin_form.email, this.admin_form.password)
        .then((data) => {
          if (data.two_factor_required) {
            this.error = null;
            this.two_factor.token = data.token;
            this.two_factor.phone_mask = data.phone_mask;
            return;
          }

          window.location.href = "https://dashboard.qrwaiter.com.ua";
        })
        .catch((error) => {
          this.error = error;
          this.admin_form.password = null;
        });
    },
    loginTwoFactor() {
      AuthAPI.loginAdminTwoFactor(this.two_factor.token, this.two_factor.code)
        .then(() => {
          window.location.href = "https://dashboard.qrwaiter.com.ua";
        })
        .catch((error) => {
          this.error = error;
          this.two_factor.code = null;
        });
    },
    loginPersonalQueue() {
      AuthAPI.loginPersonalQueue(this.queue_form.qId, this.queue_form.pin)
        .then(() => {
          window.location.href = "https://queue.qrwaiter.com.ua/orders";
        })
        .catch((error) => {
          this.error = error;
        });
    },
    loginSharedQueue() {
      AuthAPI.loginSharedQueue(this.queue_form.qId)
        .then(() => {
          window.location.href = "https://queue.qrwaiter.com.ua/orders";
        })
        .catch((error) => {
          this.error = error;
        });
    },
    doQueueLogin() {
      if (this.personalQueue) {
        this.loginPersonalQueue();
      } else {
        this.loginSharedQueue();
      }
    },
    showForgotUI() {
      this.forgot_password.active = true;
    },
    sendRestore() {
      if (this.forgot_password.busy) return;

      this.forgot_password.busy = true;

      AuthAPI.forgotPassword(this.forgot_password.email)
        .then(() => {
          this.forgot_password.sent = true;

          this.forgot_password.busy = false;
        })
        .catch((error) => {
          console.error(error);
          alert(`Error :(`);
          this.forgot_password.busy = false;
        });
    },
  },
  mounted() {
    if (this.isMobile) {
      this.adminPanel = false;
    }

    if (this.$route.query.email) {
      this.admin_form.email = this.$route.query.email;
    }

    if (this.$route.query.qid) {
      this.adminPanel = false;
      this.queue_form.qId = this.$route.query.qid;
    }

    window.location.href = 'https://dashboard.qrwaiter.com.ua/login'
  },
  computed: {
    isMobile() {
      return window.innerWidth < 550;
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Chadwick", Arial, Helvetica, sans-serif;
}

.login-wrapper {
  height: 100vh;
  width: 100%;
  background: #1d1d1d;
}

nav img {
  margin: 2vh 5vh;
  height: 3.3vh;
  width: auto;
  cursor: pointer;
}

h2 {
  line-height: 0.8;
  color: #464646;
  padding: 22vh 11vh 0;
  text-align: right;
  font-weight: 100;
  font-size: 8vh;
}

form.login-form {
  background: #464646;
  padding: 3vh 11vh 8vh;
  height: 38vh;
  width: 65%;
}

form p {
  color: #908f8d;
  font-size: 2.3vh;
  width: 50%;
}

.btn-disable {
  background: #908f8d !important;
}

main {
  padding-top: 6vh;
  display: flex;
  justify-content: flex-end;
}

input {
  display: block;
  width: 50vh;
  outline: none;
  border: none;
  background: #000000;
  color: #ededed;
  font-size: 3vh;
  padding: 1vh;
}

input.login {
  margin-bottom: 2.5vh;
}

span.login-mode {
  display: block;
  cursor: pointer;
  font-size: 3.2vh;
  padding: 1.7vh 2.5vh;
  height: fit-content;
  margin-right: -1px;
  color: #908f8d;
}

span.login-mode.active {
  background: #464646;
  color: #ededed;
}

.queue-panel .tab {
  display: inline-block;
  padding: 1vh;
  cursor: pointer;
  width: 20vh;
  text-align: center;
  margin: 0 auto;
  font-size: 2.3vh;
  color: #908f8d;
  margin-bottom: 2.5vh;
  background: transparent;
}

.queue-panel .tab.active {
  color: #ededed;
  background: #000000;
}

.btn {
  width: 50vh;
  display: block;
  margin-top: 9vh;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  padding: 1.3vh 0;
  font-size: 2.3vh;
}

.btn.queue {
  margin-top: 5.5vh;
}

.btn-yellow {
  margin-top: 6.6vh !important;
  background: #ffd2a8 !important;
}

.btn-green {
  background: #129b81;
  color: #ededed;
}

a.support {
  text-decoration: none;
  color: #cbd9e6;
  position: absolute;
  bottom: 4.2vh;
  left: 5.5vh;
  font-size: 2.5vh;
}

.login-error {
  color: #e2223b;
  margin-top: 2vh;
  font-size: 2vh;
}

.input-error {
  border: 2px solid #e2223b;
}

.mobile-admin-panel {
  color: #ededed;
  text-align: center;
}

.forgot-password-link {
  color: #908f8d;
  margin-top: 1vh;
  display: inline-block;
}

.forgot-password-sent {
  color: #ededed;
  font-size: 2.4vh;
  width: 60%;
}

@media screen and (max-width: 768px) {
  .login-wrapper {
    overflow-x: hidden;
  }
  nav img {
    height: 3.3vh;
    margin: 2vh;
  }

  form p {
    width: 100%;
  }

  h2 {
    padding: 3.7vh 0 0 0;
    text-align: center;
    font-size: 9.4vw;
  }
  main {
    flex-flow: column wrap;
  }
  span.login-mode {
    text-align: center;
  }
  form.login-form {
    width: 100%;
    height: auto;
    padding: 2vh 5.4vw;
  }

  .btn,
  input {
    width: 100%;
  }
}
</style>
<template>
  <div :class="{
      'images-switcher-container': true,
      'images-switcher-horizontal': !!horizontal
    }">
      <div 
        v-for="(image,index) in images"
        :key="index"
        :class="{
            'switcher-button': true,
            'switcher-button-active': currentImageIndex == index,
            'switcher-button-horizontal': !!horizontal
        }" @click="onSelect(index)">

      </div>
  </div>
</template>

<script>
export default {
    props: {
        horizontal: {
            type: Boolean,
            default: false
        },
        images: Array,
        interval: {
            type: Number,
            default: 2500
        }
    },
    data() {
        return {
            currentImageIndex: 0,
            switchTimerId: 0
        }
    },
    methods: {
        onSelect(index) {
            this.currentImageIndex = index;
            this.emitUpdate();
            clearInterval(this.switchTimerId);
        },
        nextImage() {
            this.currentImageIndex++;
            if (this.currentImageIndex == this.images.length) {
                this.currentImageIndex = 0;
            }
            this.emitUpdate();
        },
        emitUpdate() {
            this.$emit('update', this.images[this.currentImageIndex]);
        }
    },
    mounted() {
        this.switchTimerId = setInterval(this.nextImage, this.interval);
        this.emitUpdate();
    },
    beforeDestroy() {
        clearInterval(this.switchTimerId);
    }
}
</script>

<style scoped>
.switcher-button {
  background: #464646;
  height: 2vh;
  width: 2vh;
  margin-bottom: 4vh;

  cursor: pointer;
}

.images-switcher-horizontal {
    display: flex;
    position: absolute;
    bottom: -7vh;
}

.switcher-button-active {
    background: #ededed;
}

.switcher-button-horizontal:first-child {
    margin-left: 0;
}

.switcher-button-horizontal {
    margin-bottom: 0;
    margin-left: 4vh;
}

@media screen and (max-width: 768px) {
    .images-switcher-horizontal {
        bottom: -22vh;
        left: 0;
        right: 0;
        justify-content: center;
    }
}
</style>
<template>
  <div class="policy-wrapper">
      <h2 class="policy-header">{{$t('privacy.title')}}</h2>
    <div class="policy-content">
      <h3>{{$t('privacy.general')}}</h3>

      <p>
        {{$t('privacy.pt1')}}
      </p>

      <h3>{{$t('privacy.info')}}</h3>

      <p>
        {{$t('privacy.pt2')}}
      </p>

      <ul>
        <li>
          {{$t('privacy.pt3')}}
        </li>
        <li>
          {{$t('privacy.pt4')}}
        </li>
        <li>{{$t('privacy.pt5')}}</li>
        <li>{{$t('privacy.pt6')}}</li>
        <li>
          {{$t('privacy.pt7')}}
        </li>
      </ul>{{$t('privacy.pt8')}}
      <ul>
        <li>{{$t('privacy.pt9')}}</li>
        <li>{{$t('privacy.pt10')}}</li>
        <li>{{$t('privacy.pt11')}}</li>
        <li>{{$t('privacy.pt12')}}</li>
        <li>{{$t('privacy.pt13')}}</li>
        <li>{{$t('privacy.pt14')}}</li>
      </ul>{{$t('privacy.pt15')}}
      <h3>{{$t('privacy.goal')}}</h3>

      <p>{{$t('privacy.pt16')}}</p>

      <ul>
        <li>{{$t('privacy.pt17')}}</li>
        <li>{{$t('privacy.pt18')}}</li>
        <li>{{$t('privacy.pt19')}}</li>
        <li>{{$t('privacy.pt20')}}</li>
        <li>{{$t('privacy.pt21')}}</li>
      </ul>

      <h3>{{$t('privacy.security')}}</h3>

      <p>
        {{$t('privacy.pt22')}}
        <a
          href="https://mobizon.ua/privacy"
        >https://mobizon.ua/privacy</a>.
        {{$t('privacy.pt23')}}
      </p>

      <h3>{{$t('privacy.preend')}}</h3>
      <p>
        {{$t('privacy.pt24')}}
        <a
          href="https://qrwaiter.com.ua/privacy_policy"
        >qrwaiter.com.ua/privacy_policy</a>).
        {{$t('privacy.pt25')}}
        <br />
        <b>
          {{$t('privacy.pt26')}}
          <a href="mailto:team@qrwaiter.com.ua">team@qrwaiter.com.ua</a> {{$t('privacy.tg')}}
          <a href="https://t.me/QRWaiter">@QRWaiter</a>.
        </b>
        <br />
        <kbd>
          {{$t('privacy.end')}}
        </kbd>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.policy-wrapper {
    width: 90%;

    margin-left: 5%;
    margin-top: 5vh;
}

.policy-content {
    color: #ededed;
    font-size: 2vh;
    margin-bottom: 5vh;
}

.policy-header {
    color: #464646;
    font-size: 6vh;
}

.policy-content ul li {
    margin-left: 3vh;
}

.policy-content kbd {
    margin-top: 5vh;
}

.policy-content h3 {
   margin-top: 2.5vh;
}
</style>
<template>
    <div class="terms-wrapper">
        <h2 class="terms-header">Публічна оферта</h2>
        <div class="terms-content">
            <p>
                Прочитайте текст даної оферти і якщо Ви не згодні з яким-небудь пунктом
                цієї оферти, чи не зрозуміли будь-який пункт цієї оферти, пропонуємо Вам
                відмовитися від використання сервісу Виконавця, або уточнити інформацію,
                яка Вас цікавить за поштовою адресою
                <a href="mailto:team@qrwaiter.com.ua">team@qrwaiter.com.ua</a> або
                Telegram каналом - <a href="https://t.me/QRWaiter">@QRWaiter</a>. У
                випадку прийняття умов даної публічної оферти, Ви погоджуєтеся з усіма
                умовами оферти і підтверджуєте, що Вам зрозумілі всі її положення.
            </p>
            <h4>Пропозиція (публічна оферта)</h4>
            <p>
                Викладений нижче текст Договору, адресований фізичним особам і є
                офіційною публічною пропозицією (відповідно до ст. 641 Цивільного
                кодексу України) ФОП Кошун Миколи Мирославовича,
                що внесений до Єдиного державного реєстру юридичних осіб, фізичних
                осіб-підприємців та громадських формувань під номером 3614808772, названий
                в подальшому – "Виконавець".
            </p>
            <b></b>
            <p>
                Договір вважається укладеним в порядку ст. 642 Цивільного кодексу
                України і набуває сили договору приєднання з моменту здійснення Вами
                дій, передбачених п. 4.4. цього Договору, що означають повне і
                беззастережне прийняття Вами всіх умов Договору без будь-яких виключень
                та/або обмежень (акцепт).
            </p>
            <h4>1. Загальні положення</h4>
            <p>
                Кожна Сторона гарантує іншій Стороні, що володіє необхідною
                дієздатністю, а також всіма правами і повноваженнями, необхідними і
                достатніми для укладання і виконання Договору відповідно до його умов.
                Чинна версія даного Договору завжди розміщена на Сайті Виконавця і в
                обов’язковому порядку є доступною для ознайомлення Замовнику до моменту
                здійснення ним акцепту умов Договору.
            </p>
            <h4>2. Терміни, що використовуються в цьому Договорі</h4>
            <p>
                "Виконавець" — юридична особа, що надає Послуги із консультування з
                питань інформатизації шляхом забезпечення доступу до Сервісу та
                технічної підтримки його діяльності.
                <br />
                <br />
                "Замовник" — фізична особа, що досягла 18-річного віку, яка має повну
                цивільну дієздатність, яка отримує від Виконавця послуги (доступ до
                сервісу).
                <br />
                <br />
                "Сайт Виконавця" — веб-сторінка, доступна у мережі Інтернет за адресою:
                https://qrwaiter.com.ua.
                <br />
                <br />
                "Сервіс", "сервіс QRWaiter" — комплекс інтегрованих комп'ютерних систем,
                які поєднують апаратні засоби, програмне забезпечення, комунікаційні
                технології та технічних рішень, спрямованих на оптимізацію роботи
                закладів громадського харчування за допомогою QR-кодів.
                <br />
                <br />
                Надалі по тексту Договору Замовник і Виконавець також називаються разом
                "Сторони", а кожен окремо — "Сторона".
            </p>
            <h4>
                3. Предмет Договору
            </h4>
            <p>
                Предметом цього Договору є надання Виконавцем послуг Замовнику, спрямованих на забезпечення доступу до
                Сервісу QRWaiter, на визначених Договором умовах.
            </p>
            <h4>
                4. Порядок укладення Договору
            </h4>
            <p>
            <ol>
                <li>
                    Договір укладається між Замовником і Виконавцем у формі договору приєднання (ст. 634 Цивільного
                    кодексу України).
                </li>
                <li>
                    Акцепт умов Договору означає повне і беззастережне прийняття Замовником всіх умов Договору, без
                    будь-яких виключень та/або обмежень і рівносильний укладенню двостороннього письмового Договору про
                    надання послуг Замовнику.
                </li>
                <li>
                    Акцептом умов Договору є здійснення Замовником дій, визначених п. 4.4. Договору.
                </li>
                <li>
                    Замовник здійснює акцепт Договору після ознайомлення з його умовами, викладеними на Сайті Виконавця
                    шляхом сукупного або окремого виконання будь-якої з наступних дій:
                    <ul>
                        <li>
                            реєстрація Замовником акаунту (облікового запису) шляхом заповнення спеціалізованої форми,
                            розміщеної на Сайті Виконавця;
                        </li>
                        <li>
                            заповнення меню закладу, додавання опису чи зображення страв;
                        </li>
                        <li>
                            генерування та завантаження QR-кодів;
                        </li>
                        <li>здійснення оплати Замовником послуг Виконавця згідно тарифів, вказаних на Сайті Виконавця.
                        </li>
                    </ul>
                </li>
                <li>
                    Будь-яка з вказаних у п. 4.4. дій в окремості, яка виконана Замовником, свідчить про те, що Замовник
                    ознайомлений з даним Договором і згідний з його умовами в повному обсязі.
                </li>
                <li>
                    Внесення оплати та/або передплати за Послугу є підтвердженням замовлення Послуги.
                </li>
                <li>
                    Договір вважається укладеним і вступає в силу з моменту акцепту і діє протягом усього терміну
                    отримання послуг, або до моменту його розірвання на підставах, визначених умовами Договору та/або
                    нормами чинного законодавства України.
                </li>
                <li>
                    Термін акцепту не обмежений.
                </li>
                <li>
                    Укладання Договору означає, що Замовник:
                    <ul>
                        <li>
                            у необхідному для нього обсязі ознайомився з правилами надання Послуги;
                        </li>
                        <li>
                            приймає всі умови вказаного Договору без зауважень.
                        </li>
                    </ul>
                </li>
            </ol>
            </p>
            <h4>5. Порядок розрахунків</h4>
            <p>
            <ol>
                <li>
                    Послуга надається Замовнику виключно на попередній платній основі шляхом оплати Замовником
                    відповідної кількості QR-кодів у відповідності до Тарифів, вказаних на Сайті Виконавця.
                </li>
                <li>
                    У разі, одностороннього припинення договору Виконавцем, оплата, внесена Замовником за послугу – не
                    повертається.
                </li>
                <li>
                    Оплата послуг Виконавця, а також інших фінансових зобов’язань Замовника перед Виконавцем, які можуть
                    виникати в ході співпраці із Виконавцем, здійснюються шляхом перерахування коштів на розрахунковий
                    рахунок Виконавця за допомогою системи "LiqPay".
                </li>
                <li>
                    При оформленні платної підписки Замовник може самостійно визначити період дії доступу до Сервісу і
                    вибрати налаштування порядку стягнення оплати системою "LiqPay".
                </li>
                <li>
                    Стягнення оплати за використання Послуги відбувається помісячно.
                </li>
                <li>
                    При відсутності надходження коштів в належний час, доступ до Сервісу QRWaiter автоматично
                    блокується.
                </li>
                <li>
                    При надходженні коштів на обліковий запис Замовника, – доступ до Сервісу QRWaiter автоматично
                    відновлюється.
                </li>
                <li>
                    Кошти внесені на обліковий запис Замовника не повертаються, а можуть бути використані лише для
                    оплати доступу до Сервісу.
                </li>
            </ol>
            </p>
            <h4>
                6. Порядок надання Послуг
            </h4>
            <p>
            <ol>
                <li>Послуги із консультування з питань інформатизації надаються шляхом забезпечення доступу Замовника до
                    Сервісу QRWaiter через створення Замовником аккаунту (облікового запису) на сайті Виконавця.</li>
                <li>
                    Протягом перших 14 днів після створення облікового запису доступ до Сервісу надається безкоштовно
                    (Тестовий період).
                </li>
                <li>
                    Після завершення тестового періоду використання сервісу Замовником, необхідно оформити платну
                    підписку на Послугу у відповідності до умов, встановлених в Розділі 5 "Порядок розрахунків" або
                    припинити використання Сервісу.
                </li>
            </ol>
            </p>
            <h4>
                7. Права та обов’язки Виконавця
            </h4>
            <p>
            <ol>
                <li>
                    Виконавець зобов’язаний:
                    <ul>
                        <li>
                            До початку надання Послуги надати для ознайомлення Замовнику цей Договір шляхом публікації
                            на Сайті Виконавця;
                        </li>
                        <li>
                            Організувати належну роботу Сервісу, його компонентів, для одержання Замовником
                            безперешкодного доступу до нього;
                        </li>
                        <li>
                            Здійснювати надання послуги із застосуванням готових рішень;
                        </li>
                        <li>
                            Удосконалювати Сервіс для забезпечення його безперебійної роботи.
                        </li>
                    </ul>
                </li>
                <li>
                    Виконавець має право:
                    <ul>
                        <li>
                            Вносити зміни до програмного забезпечення та серверів Виконавця з метою покращення роботи
                            Сервісу.
                        </li>
                        <li>
                            Призупинити надання Послуги при несвоєчасному виконанні Замовником своїх фінансових
                            зобов’язань перед Виконавцем.
                        </li>
                        <li>
                            Вносити в односторонньому порядку зміни в цей Договір, в тому числі і в діючі Тарифи на
                            Послуги, з попередньою їх публікацією на Сайті Виконавця.
                        </li>
                    </ul>
                </li>
            </ol>
            </p>
            <h4>
                8. Права та обов’язки Замовника:
            </h4>
            <p>
                Замовник зобов’язаний:
            <ul>
                <li>
                    Упродовж усього періоду надання послуг дотримуватися умов цього Договору.
                </li>
                <li>
                    При реєстрації акаунту (облікового запису) повідомляти (власноруч вносити) достовірні дані і
                    відомості.
                </li>
                <li>
                    Не надавати доступу до свого облікового запису або реєстраційної інформації третім особам.
                </li>
                <li>
                    Дотримуватись заходів безпеки для свого облікового запису.
                </li>
                <li>
                    Повідомляти Виконавця про проблеми з функціонуванням (доступом) до сайту Замовника чи проблемами в
                    роботі Сервісу.
                </li>
                <li>
                    Використовувати Сервіс для законного здійснення господарської діяльності.
                </li>
                <li>
                    Не вводити в оману кінцевих користувачів (Споживачів).
                </li>
            </ul>
            <br>
            Замовник має право:
            <ul>
                <li>
                    Користуватись послугами згідно умов даного Договору;
                </li>
                <li>
                    Вимагати від Виконавця виконання вимог даного Договору;
                </li>
                <li>
                    Знайомитися з порядком та умовами надання послуг Виконавцем;
                </li>
                <li>
                    Вносити пропозиції щодо покращення роботи Виконавця;
                </li>
            </ul>
            </p>
            <h4>
                9. Безпека Сервісу
            </h4>
            <p>
                Замовнику заборонено втручатися в роботу Сервісу, зокрема:
            <ul>
                <li>Копіювати, продавати, передавати, призначати, встановлювати права, аналізувати код, здійснювати
                    зворотний інжиніринг.</li>
                <li>Завантажувати або передавати файли, які містять віруси, пошкоджені файли або дані, або будь-яке інше
                    програмне забезпечення, що може вплинути на роботу Сервісу.</li>
                <li>Намагатися отримати паролі або іншу особисту інформацію від інших користувачі, включаючи інформацію,
                    яка дозволяє встановити особу.</li>
                <li>Використовувати канали підтримки для створення помилкових звітів в QRWaiter чи не за призначенням.
                </li>
                <li>Вчиняти шахрайські дії, чи інші неправомірні дії.</li>
                <li>Втручатися, зламувати або розшифровувати будь-які передачі даних "на" або "з" серверів Виконавця.
                </li>
                <li>Втручатися в здатність інших користувачів отримувати доступ до Сервісу, в тому числі переривати
                    роботу, перевантажувати сервери QRWaiter, або вчиняти дії, які перешкоджають роботі Сервісу і
                    використання його іншими користувачами.</li>
            </ul>
            </p>
            <h4>
                10. Відповідальність сторін
            </h4>
            <p>
                Виконавець не несе відповідальності за збитки Замовника, що виникли в результаті використання Сервісу,
                відмови від використання Сервісу чи припинення надання доступу до Сервісу у зв’язку з невиконанням умов
                Договору.
                <br>
                Виконавець несе відповідальність тільки за ті зобов’язання, які вказані в Договорі у відповідності до
                чинного законодавства України.
                <br>
                Замовник несе всю відповідальність за достовірність відомостей, вказаних ним при реєстрації акаунту
                (облікового запису) Замовника. У випадку якщо Замовник не вказав, або невірно вказав персональні дані,
                Виконавець не відповідає за збитки Замовника, понесені в результаті неможливості правильної
                ідентифікації Замовника.
                <br>
                В інших випадках невиконання або неналежного виконання своїх зобов’язань за Договором Сторони несуть
                відповідальність згідно законодавства України, з урахуванням умов Договору.
                <br>
                Сторони звільняються від відповідальності за повне або часткове невиконання своїх зобов’язань за даним
                Договором, якщо це стало наслідком обставин непереборної сили, що виникли після набуття чинності даного
                Договору в результаті подій надзвичайного характеру, які не могли бути передбаченими та яким неможливо
                було запобігти розумними заходами.
                <br>
                У випадку порушення Замовником умов цього Договору Виконавець не несе відповідальності за ті
                зобов’язання, які вказані в Договорі.
            </p>
            <h4>11. Зміни і розірвання Договору</h4>
            <p>
                Виконавець має право в односторонньому порядку вносити зміни в Договір, шляхом публікації змін на Сайті
                Виконавця. Зміни вступають в силу з моменту публікації, якщо інший термін вступу змін в силу не
                визначений додатково при їх публікації.
            </p>
            <br>
            <p>
                При внесенні Виконавцем у Договір істотних змін, що впливають на можливість використання послуг
                Замовником, Виконавець повідомляє про них Замовника, шляхом публікації їх на Сайті Виконавця не менше
                ніж за добу до вступу змін у силу.
            </p>
            <p>
                Договір може бути розірвано у наступних випадках:
            <ul>
                <li>При прийняті Замовником рішення про відмову від отримання послуг і повідомленні Виконавця про це
                    рішення шляхом вчинення відповідних дій на сайті Виконавця;</li>
                <li>У разі припинення оплати послуг Виконавця;</li>
                <li>На інших підставах, передбачених цим Договором та/або чинним законодавством України. </li>
            </ul>
            </p>
            <h4>12. Вирішення суперечок</h4>
            <p>
                Всі суперечки і розбіжності, що виникли або могли виникнути з приводу невідповідного виконання вимог
                даного Договору, підлягають вирішенню шляхом переговорів на підставі письмового звернення однієї зі
                Сторін до другої.
            </p>
            <br>
            <p>
                Виконавець після отримання від Замовника претензії, зобов’язаний протягом 5 (п’яти) робочих днів її
                розглянути та направити Замовнику послуг письмову вмотивовану відповідь.
            </p>
            <br>
            <p>
                Якщо суперечка між Сторонами не буде розв’язана в претензійному порядку, будь-яка із Сторін має право
                звернутися за розв’язанням суперечки в суд за місцем знаходження Виконавця.
            </p>
            <h4>13. Інші положення</h4>
            <p>
                Замовник гарантує, що всі умови цього Договору йому зрозумілі і він приймає їх безумовно і в повному
                обсязі.
            </p>
            <br>
            <p>
                З питань, що не є врегульованими цим Договором, Сторони зобов’язуються керуватися нормами чинного
                законодавства України.
            </p>
            <br>
            <p>
                Сторони домовилися, що Виконавцю належать всі виключні майнові права на об’єкти інтелектуальної
                власності, які створені Виконавцем та надаються Замовнику в процесі надання послуг, у тому числі
                передбачені Цивільним Кодексом України, Законом України "Про авторське право і суміжні права", Бернською
                конвенцією про охорону літературних і художніх творів (Паризького акту від 24.07.1971, зміненого
                02.10.1979), Всесвітньою конвенцією про авторське право 1952 року, а також іншими міжнародними
                нормативно-правовими актами, а саме:
            <ul>
                <li>
                    право на використання об’єкта інтелектуальної власності,
                </li>
                <li>
                    виключне право дозволяти використовувати об’єкт інтелектуальної власності,
                </li>
                <li>
                    виключне право перешкоджати неправомірному використанню об’єкта інтелектуальної власності, у тому
                    числі забороняти таке використання,
                </li>
                <li>
                    а також інші права, які існують на сьогодні або будуть існувати в майбутньому.
                </li>
            </ul>
            </p>
            <br>
            <p>
                Сторони зобов’язуються відповідно до Закону України "Про захист персональних даних" від 1 червня 2010
                року №2297-VI, забезпечити належний захист персональних даних від незаконної обробки, а також від
                незаконного доступу до них, в тому числі вжиття необхідних заходів щодо недопущення розголошення
                персональних даних Замовника та/або іншими уповноваженими особами Сторін, яким такі персональні дані
                було довірено або які стали їм відомі у зв’язку з виконанням обов’язків за цим Договором.
            </p>
            <h4>
                Виконавцем за цим договором є:
            </h4>
            <p>
                <b>ФОП Кошун М.М.</b>
                <br>
                код ЄДРПОУ: 3614808772
                <br>
                Місцезнаходження за адресою: 88017, Закарпатська обл., місто Ужгород, вул. Рилєєва, будинок 4Б, квартира
                14.
                <br>
                Телефон: <a href="tel:+380994318838">+380994318838</a>
                <br>
                Адреса електронної пошти: <a href="mailto:team@qrwaiter.com.ua">team@qrwaiter.com.ua</a>
                <br>
                <br>
            </p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.terms-wrapper {
    width: 90%;

    margin-left: 5%;
    margin-top: 5vh;
}

.terms-content {
    color: #ededed;
    font-size: 2vh;
    margin-bottom: 5vh;
}

.terms-header {
    color: #464646;
    font-size: 6vh;
}

.terms-content ul li {
    margin-left: 3vh;
}

.terms-content kbd {
    margin-top: 5vh;
}

.terms-content h4 {
    margin-top: 2.5vh;
}
</style>
<template>
  <button @click="$emit('click')" :class="btnClass" :size="size" :disabled="disabled">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    variant: String,
    size: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClass() {
      if (this.variant == 'secondary') return 'secondary'
      return [this.variant, this.size];
    },
  },
};
</script>

<style scoped>
button {
  display: flex;
  font-size: 14px;
  padding: 7px 16px;
  line-height: normal;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  border: 1px solid #1f1f1f;
}

.secondary {
  background: transparent;
  color: #ededed;
  border: 1px solid #ededed;
}

.secondary.green {
  border:1px solid #34AD96;
  color: #34AD96;
  background: transparent;
}

.sm {
  padding: 3px 6px;
}

.md {
  padding: 7px 14px;
}

.lg {
  padding: 11px 20px;
}

.dark {
  background: #1f1f1f;
  color: #ededed;
}

.green {
  background: #129b81;
  color: #ededed;
}

.transparent {
  background: transparent;
  border: 1px solid #1F1F1F;
  color: #1F1F1F;
}

button:disabled {
  background: #bfbfbf;
  border: 1px solid #bfbfbf;
  cursor: not-allowed;
  color: #737373;
}
button.transparent:disabled {
  color: #a6a6a6;
  border: 1px solid #a6a6a6;
  background: transparent;
}

/* button.sm svg {
  height: 12px;
  width: 12px;
} */

button.lg svg {
  width: 16px;
  height: 16px;
}

button.sm.icon {
  padding: 4px;
}

button.md.icon {
  padding: 8px;
}

button.lg.icon {
  padding: 11px;
}
</style>
<template>
  <div class="login-wrapper">
    <router-link tag="nav" to="/">
      <img src="/icons/qr-white-logo.svg" alt="logo" />
    </router-link>
    <h2>{{ $t('password_recovery.title') }} </h2>
    <main v-if="!isMobile">
      <form class="login-form admin-panel" @submit.prevent="send">
        <div v-if="!success">
          <input type="password" v-model="form.password" class="login" :placeholder="$t('password_recovery.pass_ph')"
            required />
          <input type="password" v-model="form.repeat" :placeholder="$t('password_recovery.repeat_ph')" required />
          <div class="password-strength" v-if="form.password">
            <span class="bad-password" v-if="passwordScore < 2">{{ $t('register.tooweak') }}</span>
            <span class="strong-password" v-else>{{ $t('register.good') }}</span>
          </div>
          <button class="btn btn-yellow" type="submit" :disabled="passwordScore < 2 || form.password != form.repeat">{{
      $t('password_recovery.confirm') }}</button>
          <div class="login-error" v-if="error">{{ $t('password_recovery.fail') }}</div>
        </div>
        <div v-if="success">
          <h3 class="success-reset">{{ $t('password_recovery.success') }}</h3>
          <router-link to="/login" tag="button" class="btn btn-yellow">{{ $t('password_recovery.login') }}</router-link>
        </div>
      </form>
    </main>
    <div class="mobile-hint" v-else>
      {{ $t('password_recovery.desktop_only') }}
    </div>
    <a href="#" @click.prevent="openIntercom" class="support">Support</a>
  </div>
</template>

<script>
import AuthAPI from "@/api/auth";

import zxcvbn from 'zxcvbn'

export default {
  data () {
    return {
      form: {
        password: null,
        repeat: null,
      },
      success: false,
      error: null,
    };
  },
  methods: {
    send () {
      this.error = null;

      if (this.form.password != this.form.repeat) {
        return;
      }

      AuthAPI.resetPassword(this.recoveryToken, this.form.password)
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.error = error;
        });
    },
    openIntercom () {
      // window.$chatwoot.toggle("open");
      window.Intercom('show');
    }
  },
  mounted () { },
  computed: {
    isMobile () {
      return window.innerWidth < 550;
    },
    recoveryToken () {
      return this.$route.params.token;
    },
    passwordScore () {
      if (!this.form.password) return 0;

      return zxcvbn(this.form.password).score;
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Chadwick", Arial, Helvetica, sans-serif;
}

.login-wrapper {
  height: 100vh;
  width: 100%;
  background: #1d1d1d;
}

nav img {
  margin: 2vh 5vh;
  height: 3.3vh;
  width: auto;
  cursor: pointer;
}

h2 {
  line-height: 0.8;
  color: #464646;
  padding: 22vh 11vh 0;
  text-align: right;
  font-weight: 100;
  font-size: 8vh;
}

form.login-form {
  background: #464646;
  padding: 3vh 11vh 8vh;
  height: 38vh;
  width: 65%;
}

form p {
  color: #908f8d;
  font-size: 2.3vh;
  width: 50%;
}

.btn-disable {
  background: #908f8d !important;
}

main {
  padding-top: 6vh;
  display: flex;
  justify-content: flex-end;
}

input {
  display: block;
  width: 50vh;
  outline: none;
  border: none;
  background: #000000;
  color: #ededed;
  font-size: 3vh;
  padding: 1vh;
}

input.login {
  margin-bottom: 2.5vh;
}

span.login-mode {
  display: block;
  cursor: pointer;
  font-size: 3.2vh;
  padding: 1.7vh 2.5vh;
  height: fit-content;
  margin-right: -1px;
  color: #908f8d;
}

span.login-mode.active {
  background: #464646;
  color: #ededed;
}

.queue-panel .tab {
  display: inline-block;
  padding: 1vh;
  cursor: pointer;
  width: 20vh;
  text-align: center;
  margin: 0 auto;
  font-size: 2.3vh;
  color: #908f8d;
  margin-bottom: 2.5vh;
  background: transparent;
}

.queue-panel .tab.active {
  color: #ededed;
  background: #000000;
}

.btn {
  width: 50vh;
  display: block;
  margin-top: 9vh;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  padding: 1.3vh 0;
  font-size: 2.3vh;
}

.btn.queue {
  margin-top: 5.5vh;
}

.btn-yellow {
  margin-top: 6.6vh !important;
  background: #ffd2a8 !important;
}

.btn-green {
  background: #129b81;
  color: #ededed;
}

.success-reset {
  color: #129b81;
  font-size: 2.5vh;
}

a.support {
  text-decoration: none;
  color: #cbd9e6;
  position: absolute;
  bottom: 4.2vh;
  left: 5.5vh;
  font-size: 2.5vh;
}

.login-error {
  color: #e2223b;
  margin-top: 2vh;
  font-size: 2vh;
}

.input-error {
  border: 2px solid #e2223b;
}

.bad-password {
  color: #e2223b;
}

.strong-password {
  color: #129b81;
}

.password-strength {
  margin-top: 0.5vh;
}

.mobile-admin-panel {
  color: #ededed;
  text-align: center;
}

.forgot-password-link {
  color: #908f8d;
  margin-top: 1vh;
  display: inline-block;
}

.forgot-password-sent {
  color: #ededed;
  font-size: 2.4vh;
  width: 60%;
}

.mobile-hint {
  width: 100%;
  text-align: center;

  margin-top: 16px;
  font-size: 2.1vh;
  color: #ededed;
  padding: 0 16px;
}

@media screen and (max-width: 768px) {
  .login-wrapper {
    overflow-x: hidden;
  }

  nav img {
    height: 3.3vh;
    margin: 2vh;
  }

  form p {
    width: 100%;
  }

  h2 {
    padding: 3.7vh 0 0 0;
    text-align: center;
    font-size: 9.4vw;
  }

  main {
    flex-flow: column wrap;
  }

  span.login-mode {
    text-align: center;
  }

  form {
    width: 100%;
    height: auto;
    padding: 2vh 5.4vw;
    background: transparent;
  }

  .btn,
  input {
    width: 100%;
  }
}
</style>
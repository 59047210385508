export default {
    landing: {
        see: `Краще 1 раз побачити`,
        how: `Як це працює?`,
        guests: `Ваші гості отримують доступ до цифрового меню через QR-коди в закладі
        та посилання в Ваших соц. мережах`,
        app: `Або ж за допомогою додатку QR`,
        stickers:`QR-СТІКЕРИ НА СТОЛАХ`,
        design: `Дизайнерські стікери,
        які нікого не залишать байдужим
        та 100% привернуть увагу.`,
        waterproof: `Водостійкі. Не царапаються.`,
        admin: `Замовити стікери можна з Адмін Панелі закладу`,
        algorythm: `Алгоритм персоналізованих смарт-рекомендацій, який порадить, що замовити кожному гостю.`,
        pay3: `3 СПОСОБИ РОЗРАХУВАТИСЬ`,
        visitorspay: `Ваші гості завжди можуть обрати зручний спосіб оплати замовлення.`,
        pay: `Онлайн. Готівкою. Терміналом.`,
        liqpay: `Онлайн оплата працює через LiqPay`,
        multilangtitle: `МУЛЬТИМОВНЕ МЕНЮ`,
        multilang: `Мовний бар’єр успішно знищено!
        Меню відображається на всіх мовах.`,
        autotranslate: `Система автоматично перекладає меню на мову пристрою гостя.`,
        quicktitle: `ШВИДКИЙ ДОСТУП`,
        quick1: `Для того щоб замовити каву не потрібно завантажувати додаток та вводити безліч паролів.`,
        quick2: `Потрібно лише відкрити камеру та навести на QR-код.`,
        comfortabletitle: `ЗРУЧНИЙ ІНТЕРФЕЙС`,
        comfortable1: `Очевидно, що паперове меню не може вмістити в собі всі описи, ціни та варіанти страв.`,
        comfortable2: `А як щодо світлого та темного інтерфейсу, який автоматично перемикається протягом дня?`,
        comfortable3: `Паперове меню так не може.`,
        tabletitle: `РОЗПІЗНАВАННЯ СТОЛІВ`,
        table1: `Наша система вміє автоматично розпізнавати з якого стола було зроблено замовлення.`,
        table2: `Більше, ніж просто посилання на сайт.`,
        servicestitle: `ПОСЛУГИ`,
        services1: `Є речі, які неможливо оцифрувати. Поки що неможливо.`,
        services2: `Принести прибори, прибрати столик, покликати офіціанта...`,
        services3: `– всі ці послуги Ваші гості можуть замовити в 1 клік.`,
        ez1: `ПРИЙМАТИ ЗАМОВЛЕННЯ`,
        ez2: `ЩЕ НІКОЛИ НЕ БУЛО ТАК`,
        ez3: `ПРОСТО`,
        ez4: `Замовлення в закладі,
        доставка, передзамовлення
        чи навіть бронювання столику`,
        ez5: `– вся інформація в одному додатку для персоналу`,
        postitle: `POS ІНТЕГРАЦІЇ`,
        pos1: `ІНТЕГРОВАНІ З POSTER`,
        pos2: `З нашими партнерами, Poster, ми зробили глибоку двухсторонню інтеграцію.
        Це означає, що будь-які зміни в нашій системі автоматично онлайн відображаються в системі Poster.`,
        pos3: `А приймати замовлення можна прямо з терміналу Poster.`,
        marketplace: `<span>ДОДАТКИ</span> ios | android`,
        marketplace1: `Додатково до локальних систем можна розмістити свій заклад в нашому маркеплейсі`,
        more: `Детальніше`,
        watch: `Переглянути маркетплейс`,
        partners: `НАШІ ПАРТНЕРИ`,
        partners1: `Більше 100 закладів вже з нами!`,
        review: `Відгук від`,
        intro: {
            header: 'ЦИФРОВЕ МЕНЮ <span>для вашого закладу</span>',
            action:'Підключити безкоштовно',
            contact:'Зв’язатись'
        }
    },
    prices: {
        inside_lite: {
            title: 'Цифрове меню для Вашого закладу.',
            subtitle: 'Нічого зайвого, тільки потрібна інформація. Вам не потрібно встановлювати додаток для персоналу, та підключати інтеграцію. Це просто меню, як паперове, тільки цифрове.',
        },
        inside: {
            title: '“Якщо вже оцифровувати процес замовлення - то весь!”, подумали ми і зробили систему INSIDE.',
            subtitle: 'Ваші гості можуть не тільки переглянути фото страв та ціни, але й зручно замовити, залишити коментар, чайові та розрахуватись. Навіть коли офіціанта немає поруч - цифрове меню завжди під рукою!',
            ai_text: `Доречі, QRWaiter AI робить додаткові
            продажі автоматично, і в нього
            не буває поганого настрою)`,
        },
        preorder: {
            title: 'Збільшуйте потенціал свого закладу!',
            subtitle: `Збільшуйте потенціал власного закладу!
            Для цього не обов’язково арендувати додаткову площу та збільшувати кількість столів - можна просто підключити систему PREORDER.`,
            subtitle2: 'Ваші гості зможуть замовляти наперед “з собою” або бронюючи стіл. Це must-have для годин пік в будній день.'
        },
        delivery: {
            title: 'Бажаєте запустити власну доставку? Ми допоможемо!',
            subtitle: ` Вам потрібно тільки підключити систему DELIVERY.
            Приймайте замовлення з доставкою, де вже вказана вся необхідна інформація. Ім’я гостя, номер телефону, куди доставити та на яку годину.`,
            subtitle2: 'Залишилось тільки приготувати та відправити.',
        },
        reserve: {
            title: 'Бронювання столиків вже оцифроване!',
            subtitle: 'Ваші гості можуть забронювати стіл в 2 кліки.',
            subtitle2: 'Це швидше, ніж подзвонити в заклад.',
            subtitle3: 'Ви можете вказати максимальну кількість осіб та мінімальний депозит.'
        }
    },
    page_switcher: {
        for_cafes: 'ДЛЯ ЗАКЛАДІВ',
        for_users: 'ДЛЯ ГОСТЕЙ'
    },
    demo: {
        mobile: `Ця сторінка доступна тільки на мобільній версії. Проскануйте QR-код, щоб відкрити мобільну версію сайту.`
    },
    login: {
        back: `З ПОВЕРНЕННЯМ!`,
        admin: `Адмін панель закладу`,
        queue: `Панель замовлень`,
        log: `Ваш логін`,
        password: `Ваш пароль`,
        login: `Увійти`,
        failed: `Не вдалось виконати вхід. Перевірте правильність логіну та паролю.`,
        num: `На Ваш номер`,
        sent: `було відправлено СМС з кодом.`,
        confirm: `Підтвердити`,
        personal: `Персональна`,
        shared: `Спільна`,
        id: `ID закладу`,
        pin: `Ваш пін-код`,
        orders: `До замовлень ->`,
        failedid: `Не вдалось виконати вхід. Перевірте правильність ID закладу та PIN коду.`,
        forgot_password: 'Забули пароль?',
        forgot_back: 'Повернутись',
        forgot_email_ph: 'Ваша електронна адреса',
        forgot_btn: 'Відновити доступ',
        forgot_sent: 'Якщо елеткронна адреса була вказано вірно, то ми відправили листа з інструкцією для відновлення доступу до аккаунту. '
    },
    marketplace: {
        auditory: `АУДИТОРІЯ ВСІХ ЗАКЛАДІВ МОЖЕ СТАТИ ТВОЄЮ!`,
        order: `замовлення`,
        reserve: `бронювання`,
        increase: `Збільшуйте власну аудиторію за допомогою маркетплейсу QRWaiter.`,
        city: `Міста, в яких працює маркетплейс`,
        todo: `Щоб розмістити свій заклад в маркетплейсі, Вам потрібно створити аккаунт QRWaiter. Якщо Ваш заклад знаходиться в місті, де працює маркетплейс, Вам автоматично буде доступний розділ маркетплейс для налаштувань.`
    },
    privacy: {
        title: `Політика конфіденційності`,
        general: `1. Загальні положення`,
        pt1: `Умови даної політики конфіденційності росповсюджуються на всі сторінки веб-сайту
        QRWaiter, який працює за посиланням qrwaiter.com.ua. Користувач сайту повинен
        бути згодним з умовами ціїє політики для того, щоб користуватись сайтом.
        Користуючись сайтом, користувач надає згоду на збір та обробку його особистих
        даних згідно з цією політикою конфіденційності.`,
        info: `2. Збір інфромації`,
        pt2: `Адміністрація сайту QRWaiter збирає про користувача лише ту інформацію, яку він сам надає. Це може здійснюватись за
        домогою декількох способів, а саме:`,
        pt3: `Безпосередня передача особистої інформації за допомогою форми реєстрації, форми зворотнього
        зв’язку або
        будь-якої іншої форми на сайті, яка потребує безпосерднього вводу інформації користувачем`,
        pt4: `Cookies-файли браузера, який використовує користувач для переглядання і використання веб-сайту
        QRWaiter`,
        pt5: `Технологія Local Storage в браузері користувача`,
        pt6: `Технологія Geolocation API`,
        pt7: `Інші технічні протоколи передачі інформації (http, TCP), які використовуються браузером
        користувача`,
        pt8: `Інформація, яку ми збираємо, може включати:`,
        pt9: `Адресу електронної пошти користувача`,
        pt10: `Мобільний телефон користувача`,
        pt11: `IP-адрес, з якого користувач здійснив вхід на сайт QRWaiter`,
        pt12: `Назву та версію браузера, який використовує користувач`,
        pt13: `Геолокаційні координати користувача, які надаються тільки за його згодою.`,
        pt14: `Прізвище ім’я та по-батькові користувача.`,
        pt15: `Адміністрація сайту QRWaiter не несе відповідальність за достовірність, правильність і своєчасність
        інформації, яку
        надає користувач.`,
        goal: `3. Цілі збирання інформації`,
        pt16: `Адміністрація QRWaiter збирає особисту інформацію користувача для:`,
        pt17: `Ідентифікацї користувача на сайті QRWaiter`,
        pt18: `Покращення роботи сайту та послуг`,
        pt19: `Створення анонімних статистичних звітів для аналізу`,
        pt20: `Зв’язку з користувачем для вирішення питань, пов'язаних з роботою сайту та сервісу QRWaiter`,
        pt21: `Проведення розіграшів та конкурсів`,
        security: `4. Безпека особистих даних`,
        pt22: `Особиста інформація користувачів захищена усіма можливими технічними методами. Адміністрація сайту не передає особисту
        інформацію третім особам за будь-яких обставин, окрім випадків, передбачених чинним законодавством України, а саме у
        випадку офіційного запиту на отримання доступу до особистої інформації користувачів від уповноважених органів влади.
        Також при проведенні конкурсів та розіграшів QRWaiter може передавати прізвище ім’я та по-батькові, мобільний телефон чи
        адресу електронної пошти користувача Товариству з обмеженою відповідальністю «Мобізон» виключно для верифікації даних
        користувача в автоматизованому режимі.
        Окремо зазначаємо, що ознайомитися з політикою конфіденційності ТОВ «Мобізон» користувачі можуть за посиланням:`,
        pt23: `Особисті дані користувача зберігаються на серверах QRWaiter до тих пір, поки не буде видалений обліковий запис даного
        користувача. Особиста інформація користувача може бути видалена або змінена за запитом цього ж користувача.`,
        preend: `5. Прикінцеві положення`,
        pt24: `Ця політика конфіденційності розташовується в мережі Інтернет за постійним посиланням (`,
        pt25: `Адміністрація може змінити зміст Політики конфіденційності в будь-який момент часу без попереднього
        попередження.
        Нова політика конфіденційності вступає в силу з моменту її опублікування.`,
        pt26: ` З будь-яких питань, пов'язаних з цією Політикою конфіденційності, а також з будь-яких інших
        питань, що
        стосуються QRWaiter або Сервісу, звертайтеся до нас:
        за поштовою адресою`,
        tg: `або Telegram каналом -`,
        end: `Останнє оновлення: 11/07/2020
        АВТОРСЬКЕ ПРАВО. 2019-2020 QRWaiter`
    },
    register: {
        title: `Як підключитись ?`,
        reg: `Зареєструйте свій заклад`,
        info: `Внесіть загальну інформацію та залиште свої контакти, щоб ми могли з Вами зв’язатись та, в разі потреби, допомоголи все налаштувати.`,
        name: `Назва закладу`,
        address: `Адреса закладу`,
        username: `Ваше ім'я`,
        role: 'Ваша посада',
        email: `Ваша електронна адреса`,
        read: `Я прочитав умови використання та політику конфеденційності та погоджуюсь з ними.`,
        failed: `Сталася помилка при реєстрації`,
        register: `Зареєструватись`,
        sent: `На вашу пошту було надіслано повідомлення для підтвердження реєстрації.`,
        click: `Натисніть на посилання в листі.`,
        great: `Чудово!`,
        onlypass: `Залишилось тільки придумати пароль.`,
        inputpass: `Введіть пароль`,
        repeat: `Повторіть пароль`,
        tooweak: `Занадто слабкий пароль.`,
        good: `Надійний пароль.`,
        create: `Створити акаунт`,
        wrong: `Невірне посилання або срок його дії закінчився.`,
        gooduse: `Приємного користування!`,
        nessesary: `Залишилось тільки внести меню та налаштувати необхідні системи.`,
        gotosettings: `Перейти до налаштувань!`,
        contact_info:`Залиште свої контакти і ми Вам зателефонуємо`,
        contact_sent:`Ми Вам зателефонуємо протягом дня`,
        contact_btn: 'Залишити заявку',
        goback:`Повернутись на головну`,
        poster_desc: 'Ви також можете створити аккаунт в декілька кліків, якщо у Вас вже є аккаунт Poster.',
        poster_button: 'Зареєструватись через Poster',

        category_ph: 'Режим роботи QRWaiter',
        category_cafe: 'Для закладів',
        category_hotel: 'Для отелів',

        account_exists: 'Аккаунт з такою адресою електронної пошти вже заресєтрований.'
    },
    pricing: {
        title: 'Рішення QRWaiter',
        monthly: 'Щомісячна підписка',
        yearlyhtml: 'Щорічна <br>підписка',
        yearly: 'Щорічна підписка',

        month: 'місяць',
        year: 'рік',
        preview_price:'БЕЗКОШТОВНО',
        preview_desc: 'Цифрове меню в режимі "вітрини". Без можливості здійснювати замовлення',
        preview_how: 'Система працює через посилання закладу та через QR-коди розташовані на столах.',

        connect_free: 'Підключити безкоштовно',
        connect_for: 'Підключити за ',
        try_it: 'Спробувати',
        freemium_limit: 'Пробний період 14 днів',
        save: 'Економія',

        delivery_desc: 'Цифрове меню для гостей закладу з можливістю здійснювати замовлення на доставку.',
        delivery_how: 'Система працює через посилання закладу.',
        
        takeaway_desc: 'Цифрове меню для гостей закладу з можливістю здійснювати замовлення “з собою”.',
        takeaway_how: 'Система працює через посилання закладу.',

        inside_desc: 'Цифрове меню для гостей закладу з можливістю здійснювати замовлення всередині закладу.',
        inside_how: 'Система працює через QR-коди, розташовані на столах.'
    },
    terms: {
        title: `Умови використання`,
        warn: `Увага! Перед використанням даного сайту уважно прочитайте та ознайомтесь з цими умовами.`,
        confirm: `Ухвалення умов`,
        pt1: `Умови використання («Умови»), які ви читаєте, є юридично зобов'язуючою угодою між QRWaiter. (
            «QRWaiter», «ми» )
            і Вами («Ви»). Отримуючи доступ, і використовуючи веб-сайт або Систему(“Систему)” QRWaiter, Ви
            погоджуєтеся з
            тим, що прочитали, зрозуміли, приймаєте та погоджуєтесь дотримуватися цих Умов. Якщо Ви не згодні з
            цими
            Умовами, не використовуйте Послуги(“Послуги”).`,
        pt2: `QRWaiter залишає за собою право на свій розсуд переглядати або змінювати ці Умови в будь-який час, і
        Ви
        погоджуєтеся з такими змінами. Користувачі несуть відповідальність за перегляд цих Умов періодично.
        Ваше
        подальше використання Сервісу після внесення змін до цих Умов буде означати вашу згоду з
        переглянутими Умовами.
        Якщо Ви не згодні з Умовами, ваше єдиний засіб правового захисту - припинити використання Послуг та
        анулювати
        будь-які облікові записи, які Ви створили для використання Послуг.`,
        rights: `Права Компанії`,
        pt3: `Компанія залишає за собою право: Змінити Сайт на свій розсуд / Змінювати вартість і види послуг,
        терміни їх дії.`,
        pt4: `Інформація про реєстрацію`,
        pt5: `Здійснюється на сайті за допомогою форми реєстрації.`,
        pt6: `Ви не повинні ділитися своїм обліковим записом або реєстраційної інформацією, а також не
        дозволяти
        кому-небудь отримувати доступ до Вашого облікового запису або робити що-небудь ще, що може
        поставити під
        загрозу безпеку Вашого облікового запису.`,
        pt7: `У разі, якщо вам стало відомо або підозрюєте будь-яке порушення безпеки, будь-яку втрату,
        крадіжку або
        розкриття вашої реєстраційної інформації або доступ до вашого облікового запису, Ви повинні
        негайно
        повідомити QRWaiter і змінити свою реєстраційну інформацію.`,
        pt8: `Ви також несете особисту відповідальність за всі дії під вашим логіном та паролем на сайті
        “qrwaiter.com.ua”.`,
        pt9: `Ви погоджуєтесь контролювати свій обліковий запис і обмежувати використання будь-якою особою. Ви
        приймаєте
        на себе повну відповідальність за будь-яке незатверджене використання Послуг.`,
        pt10: `Право інтелектуальної власності`,
        pt11: `QRWaiter зберігають за собою всі права на матеріали Сервісу (включаючи, програмне забезпечення,
            дизайн, графіку,
            тексти, інформацію, зображення, відео, звук, музику та інші файли. Весь вміст Сервісів захищено
            відповідними
            законами про авторське право (Відомості Верховної Ради України (ВВР), 1994, № 13, ст.64 цивільного
            кодексу
            України)`,
        rules: `Правила Використання`,
        pt12: ` Ви зобов'язуєтеся не обманювати QRWaiter або інших користувачів при використанні Послуг. Якщо
        QRWaiter визначить,
        що Ви порушили ці Умови, QRWaiter може, на власний розсуд, закрити ваш обліковий запис і заборонити
        вам
        використовувати Сервіс. Ви погоджуєтеся з тим, що використання вами Послуг має бути законним і що Ви
        будете
        дотримуватися правил використання. Ви погоджуєтеся, що Ви не повинні:`,
        pt13: `Копіювати, продавати, передавати, призначати, встановлювати права, аналізувати код, здійснювати
        зворотний
        інжиніринг.`,
        pt14: `Завантажувати або передавати файли, які містять віруси, пошкоджені файли або дані, або будь-яке
        інше
        програмне забезпечення, які можуть пошкодити роботу Сервісу.`,
        pt15: `Намагатися отримати паролі або іншу особисту інформацію від інших учасників, включаючи
        інформацію, яка
        дозволяє встановити особу.`,
        pt16: `Неправильне використання каналів підтримки для створення помилкових звітів в QRWaiter.`,
        pt17: `Брати участь в шахрайських діях.`,
        pt18: `Намагатися втручатися, зламувати або розшифровувати будь-які передачі на або з серверів.`,
        pt19: `Втручатися в здатність інших користуватися Сервісом, в тому числі переривати роботу,
        перевантажувати
        сервери QRWaiter, або вживати дії, які заважають для користування всіма їх користувачами.`,
        pt20: `З будь-яких питань, пов'язаних з цими Умовами використання, а також з будь-яких інших питань, що
        стосуються
        QRWaiter або Сервісу, звертайтеся до нас:
        за поштовою адресою`,
        tg: `або Telegram каналом - @QRWaiter.`,
        end: `Останнє оновлення: 11/07/2020
        АВТОРСЬКЕ ПРАВО. 2019 QRWaiter`
    },
    visitors: {
        title: `ТВОЇ УЛЮБЛЕНІ ЗАКЛАДИ`,
        forusers: `для Користувачів`,
        forcafes: `для Закладів`,
        inside: `Роби замовлення в закладі через QR-код`,
        preorder: `Замовляй їжу
        завчасно`,
        delivery: `Замовляй страви
        з доставкою`,
        reserve: `Забронюй столик на важливу дату`,
        download: `Завантажуй додаток`,
        scan: `Скануй QR-код`
    },
    password_recovery: {
        title: 'ВІДНОВЛЕННЯ ДОСТУПУ',
        fail: 'Не вдалося змінити пароль. Перевірте правильність посилання.',
        success: 'Пароль успішно змінено.',

        login: 'Увійти',
        confirm: 'Змінити пароль',

        desktop_only: 'Відновлення паролю недоступно з мобільних пристроїв.',

        pass_ph: 'Введіть новий пароль',
        repeat_ph: 'Повторіть новий пароль'
    },
    navbar: {
        login: `Вхід`,
        register: `Зареєструватись`
    },
    footer: {
        confidential: `Конфіденційність`,
        terms: `Умови використання`,
        offert: 'Публічна оферта'
    },
    contact_title:'Будемо на зв`язку!'
}
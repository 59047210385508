export default {
    landing: {
        see: `Лучше 1 раз увидеть`,
        how: `Как это работает?`,
        guests: `Ваши гости получают доступ к цифровому меню через QR-коды в заведении
        и ссылки в Ваших соц. сетях`,
        app: `Или же с помощью приложения QR`,
        stickers:`QR-СТИКЕРЫ НА СТОЛАХ`,
        design: `Дизайнерские стикеры,
        которые никого не оставят равнодушным
        и 100% привлекут внимание.`,
        waterproof: `Водостойкие. Не царапаються.`,
        admin: `Заказать стикеры можно в Админ Панели заведения`,
        algorythm: `Алгоритм персонализированных смарт-рекомендаций, который посоветует, что заказать каждому гостю.`,
        pay3: `3 СПОСОБА РАССЧИТАТЬСЯ`,
        visitorspay: `Ваши гости всегда могут выбрать удобный способ оплаты заказа.`,
        pay: `Онлайн. Наличными. Терминалом.`,
        liqpay: `Онлайн оплата работает через LiqPay`,
        multilangtitle: `МУЛЬТИЯЗЫЧНОЕ МЕНЮ`,
        multilang: `Языковой барьер успешно уничтожен!
        Меню отображается на всех языках.`,
        autotranslate: `Система автоматически переводит меню на язык устройства гостя.`,
        quicktitle: `БЫСТРЫЙ ДОСТУП`,
        quick1: `Для того чтобы заказать кофе не нужно загружать приложение и вводить множество паролей.`,
        quick2: `Нужно только открыть камеру и навести её на QR-код.`,
        comfortabletitle: `УДОБНЫЙ ИНТЕРФЕЙС`,
        comfortable1: `Очевидно, что бумажное меню не может вместить в себе все описания, цены и варианты блюд.`,
        comfortable2: `А как насчет светлого и темного интерфейса, который автоматически переключается в течение дня?`,
        comfortable3: `Бумажное меню так не может.`,
        tabletitle: `РАСПОЗНАВАНИЕ СТОЛОВ`,
        table1: `Наша система умеет автоматически распознавать с какого стола был сделан заказ.`,
        table2: `Больше, чем просто ссылки на сайт.`,
        servicestitle: `УСЛУГИ`,
        services1: `Есть вещи, которые невозможно оцифровать. Пока невозможно.`,
        services2: `Принести приборы, убрать столик, позвать официанта...`,
        services3: `- все эти услуги Ваши гости могут заказать в 1 клик.`,
        ez1: `ПРИНИМАТЬ ЗАКАЗЫ`,
        ez2: `ЕЩЕ НИКОГДА НЕ БЫЛО ТАК`,
        ez3: `ПРОСТО`,
        ez4: `Заказ в заведении,
        доставка, предзаказ
        или даже бронирование столика`,
        ez5: `- вся информация в одном приложении для персонала`,
        postitle: `POS ИНТЕГРАЦИИ`,
        pos1: `ИНТЕГРАЦИЯ С POSTER`,
        pos2: `С нашими партнерами, Poster, мы сделали глубокую двустороннюю интеграцию.
        Это означает, что любые изменения в нашей системе автоматически онлайн отображаются в системе Poster.`,
        pos3: `А принимать заказы можно прямо из терминала Poster.`,
        marketplace: `<span>ПРИЛОЖЕНИЯ</span> ios | android`,
        marketplace1: `Дополнительно к локальным систем можно разместить свое заведение в нашем маркеплейси`,
        more: `Подробнее`,
        watch: `Посмотреть маркетплейс`,
        partners: `НАШИ ПАРТНЕРЫ`,
        partners1: `Более 100 заведений уже с нами!`,
        review: `Отзыв от`,
        intro: {
            header: 'ЦИФРОВОЕ МЕНЮ <span>для вашего заведения</span>',
            action:'Подключить бесплатно',
            contact:'Связаться'
        }
    },
    prices: {
        inside_lite: {
            title: 'Цифровое меню для Вашего заведения.',
            subtitle: 'Ничего лишнего, только нужная информация. Вам не нужно устанавливать приложение для персонала, и подключать интеграцию. Это просто меню, как бумажное, только цифровое.',
        },
        inside: {
            title: '"Если оцифровывать процесс заказа - тогда весь!", подумали мы и сделали систему INSIDE.',
            subtitle: 'Ваши гости могут не только посмотреть фото блюд и цены, но и удобно заказать, оставить комментарий, чаевые и рассчитаться. Даже когда официанта нет рядом - цифровое меню всегда под рукой!',
            ai_text: `Кстати, QRWaiter AI делает дополнительные
             продажи автоматически, и у него
             не бывает плохого настроения)`,
        },
        preorder: {
            title: 'Увеличивайте потенциал своего заведения!',
            subtitle: `Увеличивайте потенциал собственного заведения!
             Для этого не обязательно арендовать дополнительную площадь и увеличивать количество столов - можно просто подключить систему PREORDER.`,
            subtitle2: 'Ваши гости смогут заказывать заранее "с собой" или забронировать стол. Это must-have для часа пик в будний день.'
        },
        delivery: {
            title: 'Хотите запустить собственную доставку? Мы поможем!',
            subtitle: ` Вам нужно только подключить систему DELIVERY.
             Принимайте заказ с доставкой, где уже указана вся необходимая информация. Имя гостя, номер телефона, куда доставить и на какое время.`,
            subtitle2: 'Осталось только приготовить и отправить.',
        },
        reserve: {
            title: 'Бронирование столиков уже оцифровано!',
            subtitle: 'Ваши гости могут забронировать стол в 2 клика.',
            subtitle2: 'Это быстрее, чем позвонить в заведение.',
            subtitle3: 'Вы можете указать максимальное количество человек и минимальный депозит.'
        }
    },
    page_switcher: {
        for_cafes: 'ДЛЯ ЗАВЕДЕНИЙ',
        for_users: 'ДЛЯ ГОСТЕЙ'
    },
    demo: {
        mobile: `Эта страница доступна только на мобильной версии. Просканируйте QR-код, чтобы открыть мобильную версию сайта.`
    },
    pricing: {
        title: 'Решения QRWaiter',
        monthly: 'Ежемесячно',
        yearly: 'Ежегодно',

        month: 'месяц',
        year: 'год',
        preview_price:'БЕСПЛАТНО',
        preview_desc: 'Цифровое меню для гостей заведения без возможности сделать заказ',
        preview_how: 'Система работает через ссылку заведения и через QR-коды на столах',

        connect_free: 'Подключить бесплатно',
        connect_for: 'Подключить за',
        try_it: 'Попробовать',
        freemium_limit: 'Пробный период 14 дней',
        save: 'Экономия',

        delivery_desc: 'Цифровое меню для гостей заведения с возможностью сделать заказы на доставку.',
        delivery_how: 'Система работает через ссылку заведения',

        takeaway_desc: 'Цифровое меню для гостей заведения с возможностью сделать заказ "с собой"',
        takeaway_how: 'Система работает через ссылку заведения',

        inside_desc: 'Цифровое меню для гостей с возможностью сделать заказ внутри заведения',
        inside_how: 'Система работает через QR-коды, расположенные на столах'
    },
    login: {
        back: `С ВОЗВРАЩЕНИЕМ!`,
        admin: `Админ панель заведения`,
        queue: `Панель заказов`,
        log: `Ваш логин`,
        password: `Ваш пароль`,
        login: `Войти`,
        failed: `Не удалось выполнить вход. Проверьте правильность логина и пароля.`,
        num: `На Ваш номер`,
        sent: `было отправлено СМС с кодом.`,
        confirm: `Подтвердить`,
        personal: `Персональная`,
        shared: `Общая`,
        id: `ID заведения`,
        pin: `Ваш пин-код`,
        orders: `К заказам ->`,
        failedid: `Не удалось выполнить вход. Проверьте правильность ID заведения и PIN кода.`,
        forgot_password: 'Забыли пароль?',
        forgot_back: 'Назад',
        forgot_email_ph: 'Ваш электронный адрес',
        forgot_btn: 'Восстановить доступ',
        forgot_sent: 'Если электронный адрес почты был указан верно, то мы отправили на него письмо с инструкцией для восстановления доступа.'
    },
    marketplace: {
        auditory: `АУДИТОРИЯ ВСЕХ ЗАВЕДЕНИЙ МОЖЕТ СТАТЬ ТВОЕЙ!`,
        order: `заказы`,
        reserve: `бронирование`,
        increase: `Увеличивайте свою аудиторию при помощи маркетплейса QRWaiter.`,
        city: `Города, в которых работает маркетплейс`,
        todo: `Чтобы разместить свое заведение в маркетплейсе, Вам нужно создать аккаунт QRWaiter. Если Ваше заведение находится в городе, где работает маркетплейс, Вам автоматически будет доступен раздел маркетплейс для настройки.`
    },
    privacy: {
        title: `Политика конфиденциальности`,
        general: `1. Общие положения`,
        pt1: `Условия данной политики конфиденциальности распространяются на все страницы сайта
        QRWaiter, который работает по ссылке qrwaiter.com.ua. Пользователь сайта должен
        быть согласен с условиями данной политики для того, чтобы пользоваться сайтом.
        Пользуясь сайтом, пользователь дает согласие на сбор и обработку его личных
        данных согласно с политикой конфиденциальности.`,
        info: `2. Сбор инфромации`,
        pt2: `Администрация сайта QRWaiter собирает о пользователе только ту информацию, которую он сам предоставляет. Это может осуществляться при
        помощи нескольких способов, а именно:`,
        pt3: `Непосредственная передача личной информации с помощью формы регистрации, формы обратной
        связи или
        любой другой формы на сайте, требующая непосредственного ввода информации пользователем`,
        pt4: `Cookies-файлы браузера, которые использует пользователь для просмотра и использования веб-сайта
        QRWaiter`,
        pt5: `Технология Local Storage в браузере пользователя`,
        pt6: `Технология Geolocation API`,
        pt7: `Другие технические протоколы передачи информации (http, TCP), которые используются браузером
        пользователя`,
        pt8: `Информация, которую мы собираем, может включать:`,
        pt9: `Адрес электронной почты пользователя`,
        pt10: `Мобильный телефон пользователя`,
        pt11: `IP-адрес, с которого пользователь осуществил вход на сайт QRWaiter`,
        pt12: `Название и версию браузера, который использует пользователь`,
        pt13: `Геолокационные координаты пользователя, которые предоставляются только с его согласия.`,
        pt14: `Фамилия, имя, отчество пользователя.`,
        pt15: `Администрация сайта QRWaiter не несет ответственность за достоверность, правильность и своевременность
        информации, которую
        предоставляет пользователь.`,
        goal: `3. Цели сбора информации`,
        pt16: `Администрация QRWaiter собирает личную информацию пользователя для:`,
        pt17: `Идентификация пользователя на сайте QRWaiter`,
        pt18: `Улучшение работы сайта и услуг`,
        pt19: `Создание анонимных статистических отчетов для анализа`,
        pt20: `Связи с пользователем для решения вопросов, связанных с работой сайта и сервиса QRWaiter`,
        pt21: `Проведение розыгрышей и конкурсов`,
        security: `4. Безопасность личных данных`,
        pt22: `Личная информация пользователей защищена всеми возможными техническими методами. Администрация сайта не передает личную
        информацию третьим лицам при любых обстоятельствах, кроме случаев, предусмотренных действующим законодательством Украины, а именно в
        случае официального запроса на получение доступа к личной информации пользователей от уполномоченных органов власти.
        Также при проведении конкурсов и розыгрышей QRWaiter может передавать фамилию имя и отчество, мобильный телефон или
        адрес электронной почты пользователя Обществу с ограниченной ответственностью «Мобизон» исключительно для верификации данных
        только в автоматизированном режиме.
        Отдельно отмечаем, что ознакомиться с политикой конфиденциальности ООО «Мобизон» пользователи могут по ссылке:`,
        pt23: `Личные данные пользователя хранятся на серверах QRWaiter до тех пор, пока не будет удален аккаунт данного
        пользователя. Личная информация пользователя может быть удалена или изменена по запросу этого же пользователя.`,
        preend: `5. Заключительные положения`,
        pt24: `Данная политика конфиденциальности располагается в сети Интернет по постоянному адресу (`,
        pt25: `Администрация может изменить содержание Политики конфиденциальности в любой момент времени без предварительного
        предупреждения.
        Новая политика конфиденциальности вступает в силу с момента её опубликования.`,
        pt26: `По любым вопросам, связанным с этой Политикой конфиденциальности, а также любым другим
        вопросам, которые
        касаются QRWaiter или Сервиса, обращайтесь к нам:
        по почтовому адресу`,
        tg: `или Telegram каналом -`,
        end: `Последнее обновление: 11/07/2020
        АВТОРСКОЕ ПРАВО. 2019-2023 QRWaiter`
    },
    register: {
        title: `Как подключиться?`,
        reg: `Зарегистрируйте свое заведение`,
        info: `Внесите общую информацию и оставьте свои контакты, чтобы мы могли с Вами связаться и, в случае необходимости, помогли все настроить.`,
        name: `Название заведения`,
        address: `Адрес заведения`,
        username: `Ваше имя`,
        role: 'Ваша должность',
        email: `Ваш электронный адрес`,
        read: `Я прочитал условия использования и политику конфиденциальности и согласен с ними.`,
        failed: `Произошла ошибка при регистрации`,
        register: `Регистрация`,
        sent: `На вашу почту было отправлено сообщение для подтверждения регистрации.`,
        click: `Нажмите на ссылку в письме.`,
        great: `Прекрасно!`,
        onlypass: `Осталось только придумать пароль.`,
        inputpass: `Введите пароль`,
        repeat: `Повторите пароль`,
        tooweak: `Слишком слабый пароль.`,
        good: `Надежный пароль.`,
        create: `Создать аккаунт`,
        wrong: `Неверная ссылка или срок её действия истек.`,
        gooduse: `Приятного пользования!`,
        nessesary: `Осталось только внести меню и настроить необходимые системы.`,
        gotosettings: `Перейти к настройкам!`,
        contact_info:`Оставьте свои контакты и мы Вам перезвоним`,
        contact_sent:`Мы свяжемся з Вами в течении дня`,
        contact_btn: 'Оставить заявку',
        goback:`На главную`,

        category_ph: 'Режим работы QRWaiter',
        category_cafe: 'Для заведений',
        category_hotel: 'Для отелей',

        poster_desc: 'Вы также можете создать аккаунт в пару кликов, если у Вас есть аккаунт в Poster.',
        poster_button: 'Зарегистрироваться через Poster',
        account_exists: 'Аккаунт с таким адресом электронной почты уже зарегистрирован.'
    },
    terms: {
        title: `Условия использования`,
        warn: `Внимание! Перед использованием данного сайта внимательно прочитайте и ознакомьтесь с этими условиями.`,
        confirm: `Принятие условий`,
        pt1: `Условия использования («Условия»), которые вы читаете, является юридически обязывающим соглашением между QRWaiter. (
            «QRWaiter», «мы»)
            и Вами («Вы»). Получая доступ, и используя веб-сайт или Систему ("Систему)" QRWaiter, Вы
            соглашаетесь с
            тем, что прочитали, поняли, принимаете и соглашаетесь соблюдать данные Условия. Если Вы не согласны с
            этими
            Условиями, не используйте Услуги ("Услуги").`,
        pt2: `QRWaiter оставляет за собой право по своему усмотрению пересматривать или изменять эти Условия в любое время, и
        вы
        соглашаетесь с такими изменениями. Пользователи несут ответственность за просмотр этих Условий периодически.
        Ваше
        дальнейшее использование Сервиса после внесения изменений в настоящие Условия будет означать ваше согласие с
        пересмотренным Условиями.
        Если Вы не согласны с Условиями, ваше единственное средство правовой защиты - прекратить использование услуг и
        аннулировать
        любые учетные записи, которые Вы создали для использования Услуг.`,
        rights: `Права Компании`,
        pt3: `Компания оставляет за собой право: Изменить Сайт по своему усмотрению / Изменять стоимость и виды услуг,
        сроки их действия.`,
        pt4: `Информация о регистрации`,
        pt5: `Осуществляется на сайте при помощи формы регистрации.`,
        pt6: `Вы не должны делиться своей учетной записью или регистрационной информацией, а также не
        позволять
        кому-либо получить доступ к учетной записи или делать что-нибудь еще, что может
        поставить под
        угрозу безопасность Вашей учетной записи.`,
        pt7: `В случае, если вам стало известно или подозреваете любое нарушение безопасности, любую потерю,
        кражу или
        раскрытие вашей регистрационной информации или доступ к вашей учетной записи, Вы должны
        немедленно
        сообщить QRWaiter и изменить свою регистрационную информацию.`,
        pt8: `Вы также несете ответственность за все действия под вашим логином и паролем на сайте
        "qrwaiter.com.ua".`,
        pt9: `Вы соглашаетесь контролировать свою учетную запись и ограничивать использование любым лицом. Вы  принимаете  на себя полную ответственность за любое неутвержденное использование Услуг.`,
        pt10: `Право інтелектуальної власності`,
        pt11: `QRWaiter сохраняют за собой все права на материалы Сервиса (включая, программное обеспечение,
            дизайн, графика,
            тексты, информацию, изображения, видео, звук, музыку и другие файлы. Все содержимое сервисов защищенно
            соответствующими
            законами об авторском праве (Ведомости Верховной Рады Украины (ВВР), 1994, № 13, ст.64 гражданского
            кодекса
            Украины)`,
        rules: `Правила Использования`,
        pt12: ` Вы обязуетесь не обманывать QRWaiter или других пользователей при использовании услуг. Если
        QRWaiter определит,
        что Вы нарушили эти Условия, QRWaiter может, по своему усмотрению, закрыть вашу учетную запись и запретить
        вам
        использовать Сервис. Вы соглашаетесь с тем, что использование вами Услуг должно быть законным и вы
        будете
        соблюдать правила использования. Вы соглашаетесь, что вы не должны:`,
        pt13: `Копировать, продавать, передавать, назначать, устанавливать права, анализировать код, осуществлять
        обратный
        инжиниринг.`,
        pt14: `Загружать или передавать файлы, содержащие вирусы, поврежденные файлы или данные, или любое
        другое
        программное обеспечение, которые могут повредить работу Сервиса.`,
        pt15: `Пытаться получить пароли или другую личную информацию от других участников, включая
        информацию, которая
        позволяет установить личность.`,
        pt16: `Неправильное использование каналов поддержки для создания ложных отчетов в QRWaiter.`,
        pt17: `Участвовать в мошеннических действиях.`,
        pt18: `Пытаться вмешиваться, взламывать или расшифровывать любые передачи на или с серверов.`,
        pt19: `Вмешиваться в способность других использовать Сервис в том числе прерывать работу,
        перегружать
        серверы QRWaiter, или предпринимать действия, которые мешают использованию всеми пользователями.`,
        pt20: `По любым вопросам, связанным с этими Условиями использования, а также по любым другим вопросам, которые
        касаются
        QRWaiter или Сервиса, обращайтесь к нам:
        по почтовому адресу`,
        tg: `или Telegram каналу - @QRWaiter.`,
        end: `Последнее обновление: 11/07/2020
        АВТОРСКОЕ ПРАВО. 2019 QRWaiter`
    },
    visitors: {
        title: `ТВОИ ЛЮБИМЫЕ ЗАВЕДЕНИЯ`,
        forusers: `для Пользователей`,
        forcafes: `для Заведений`,
        inside: `Делай заказ в заведении через QR-код`,
        preorder: `Заказывай еду
        заранее`,
        delivery: `Заказывай блюда
        с доставкой`,
        reserve: `Забронируй столик на важную дату`,
        download: `Загружай приложение`,
        scan: `Сканируй QR-код`
    },
    password_recovery: {
        title: 'ВОССТАНОВЛЕНИЕ ДОСТУПА',
        fail: 'Не удалось сменить пароль. Проверьте корректность ссылки',
        success: 'Пароль успешно изменен',

        login: 'Войти',
        confirm: 'Сменить пароль',

        desktop_only: 'Восстановление доступа недоступно с мобильных устройств.',

        pass_ph: 'Введите новый пароль',
        repeat_ph: 'Повторите новый пароль'
    },
    navbar: {
        login: `Вход`,
        register: `Регистрация`
    },
    footer: {
        confidential: `Конфиденциальность`,
        terms: `Условия использования`,
        offert: `Публичная оферта`
    },
    contact_title:'Будем на связи!'
}

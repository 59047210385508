var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plans-container"},[_c('h2',[_vm._v(_vm._s(_vm.$t('pricing.title'))),_c('sup',[_vm._v("®")])]),_c('TabSwitcher',{staticClass:"m-auto plan-switcher",attrs:{"htmlRender":"","tabs":[
    {
      text: _vm.htmlRender ? _vm.$t('pricing.yearlyhtml') : _vm.$t('pricing.yearly'),
      value: 'year',
    },
    {
      text: _vm.htmlRendering ? _vm.$t('pricing.monthlyhtml') || _vm.$t('pricing.monthly') : _vm.$t('pricing.monthly'),
      value: 'month',
    } ]},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),_c('div',{staticClass:"plans-list gap-column-sm"},_vm._l((_vm.modes),function(mode){
  var _obj, _obj$1;
return _c('div',{key:mode,staticClass:"plan-item",class:( _obj = {}, _obj[mode] = true, _obj )},[_c('img',{attrs:{"src":("/pics/" + mode + ".jpeg"),"alt":""}}),_c('div',{staticClass:"plan-info"},[_c('h1',{staticClass:"plan-name",class:( _obj$1 = {}, _obj$1[(mode + "-line")] = true, _obj$1 )},[_vm._v(_vm._s(mode.toUpperCase()))]),_c('h3',{staticClass:"plan-price"},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.currentPricing[mode])+"$")]),_vm._v("/"+_vm._s(_vm.$t('pricing.month')))]),_c('h3',{staticClass:"plan-price-uah",class:{ 'uah-white': mode === 'inside' }},[_c('span',{staticClass:"bold"},[_vm._v("("+_vm._s(_vm.currentPricing[mode] * _vm.usdRate)+" грн")]),_vm._v("/"+_vm._s(_vm.$t('pricing.month'))+") ")]),(_vm.period == 'year')?_c('div',{staticClass:"qrw-container-title-xs red-6-color"},[_vm._v(" "+_vm._s(_vm.$t('pricing.save'))+" 20% ")]):_vm._e(),_c('p',{staticClass:"plan-title"},[_vm._v(" "+_vm._s(_vm.$t(("pricing." + mode + "_desc")))+" ")]),_c('small',{staticClass:"plan-description qrw-text-description",class:{
          'grey-2-color': mode == 'preview',
          'yellow-8-color': mode == 'takeaway',
          'blue-7-color': mode == 'delivery',
          'grey-2-color': mode == 'inside' || mode == 'preview',
        }},[_vm._v(_vm._s(_vm.$t(("pricing." + mode + "_how"))))])]),_c('div',{staticClass:"bottom-info"},[_c('BaseButton',{staticClass:"w-100 mb-8",attrs:{"variant":"primary","color":mode == 'inside' ? 'white-6' : 'black-4',"bold":"","size":"md"},on:{"click":_vm.goToRegister}},[_vm._v(" "+_vm._s(_vm.$t('pricing.try_it'))+" ")]),_c('small',{class:{
          'qrw-text-description': true,
          'yellow-8-color': mode == 'takeaway',
          'blue-7-color': mode == 'delivery',
          'grey-2-color': mode == 'inside' || mode == 'preview',
        }},[_vm._v(_vm._s(_vm.$t('pricing.freemium_limit')))])],1)])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'images-switcher-container': true,
    'images-switcher-horizontal': !!_vm.horizontal
  }},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,class:{
          'switcher-button': true,
          'switcher-button-active': _vm.currentImageIndex == index,
          'switcher-button-horizontal': !!_vm.horizontal
      },on:{"click":function($event){return _vm.onSelect(index)}}})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }